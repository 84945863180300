import { types } from '../types'
import { fetchData } from '../fetchData'

export const obtenerListaCentrosDeDia = () => {
  const proceso = 'A_CENTRO_DIA_SOLICITUD_DATOS.PRO'
  const url = `${process.env.REACT_APP_APIVEL}${proceso}`
  const data = fetchData(url)
  return (dispatch) => {
    data.then(data => {
      dispatch(cargarCentrosDeDia(data.LISTA_CENTROS_DIA))
    })
  }
}

export const cargarCentrosDeDia = (data) => {
  return {
    type: types.listarCentrosDeDia,
    payload: {
      centrosDeDia: data
    }
  }
}
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { login } from '../redux/actions/loginActions'

export const DosFactoresForm = ({ data }) => {
  const dispatch = useDispatch()
  const [inputCode, setInputCode] = useState('')

  const checkTwoFactorsCode = (e) => {
    e.preventDefault()
    const code = data.DOBLE_FACTOR_PIN
    if (inputCode.trim() === code.trim()) {
      dispatch(login(data))
    } else {
      document.getElementById('error-messagge').classList.remove('no-visible')
    }
  }

  const handleChange = (e) => {
    const newValue = e.target.value
    setInputCode(newValue)
  }

  const reloadPage = () => {
    window.location.reload()
  }

  return (
    <div className='d-flex flex-column justify-content-center align-items-center text-center'>
      <p className='fs-3'>Hemos enviado un código PIN de 4 dígitos al teléfono móvil asociado a este dispositivo.</p>
      <hr />
      <form onSubmit={checkTwoFactorsCode}>
        <label htmlFor='inputCode' className='form-label'>INTRODUZCA EL CÓDIGO</label>
        <div className="input-group mb-3">
          <input
            type='text'
            className='form-control text-center'
            value={inputCode}
            id='inputCode'
            onChange={handleChange}
            maxLength={4}
            pattern="[0-9]{4}"
            autoFocus
          />
          {/* <button className="btn btn-outline-primary" type="button" id="showCode">MOSTRAR</button> */}
        </div>
        <p className='text-danger no-visible' id="error-messagge">El código introducido no es correcto</p>
        <div className='text-center'>
          <button type='submit' className='btn btn-primary'>ENVIAR</button>
        </div>
      </form>
      <button onClick={reloadPage} className='btn btn-link'>No recibí el código</button>
    </div>
  )
}

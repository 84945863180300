import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { fetchData } from '../redux/fetchData'
import { fileToBase64 } from '../helpers/fileToBase64'
import { formatFileSize } from '../helpers/formatFileSize'
import photo from '../images/photo.png'
import { Confirm } from '../helpers/Confirm'

export const InformeDeGolpeForm = () => {

  const { vehiculoMatricula, conductorIndicativo, inConductorId, inVehiculoId, inCuadranteId, inIndicativoServicioId } = useSelector(({ loginReducer }) => loginReducer)
  const [imagenGolpe, setImagenGolpe] = useState([
    { id: 1, imagen: photo },
    { id: 2, imagen: photo },
    { id: 3, imagen: photo },
    { id: 4, imagen: photo },
    { id: 5, imagen: photo },
    { id: 6, imagen: photo }
  ])
  const [idInformeGolpe, setIdInformeGolpe] = useState('0')
  const formik = useFormik(
    {
      initialValues: {
        fecha: moment().format('YYYY-MM-DD'),
        matricula: vehiculoMatricula,
        vehiculoId: inVehiculoId,
        conductor: conductorIndicativo,
        conductorId: inConductorId,
        inCuadranteId: inCuadranteId,
        inIndicativoServicioId: inIndicativoServicioId,
        lugar: '',
        atestado: '',
        aseguradoraContrario: '',
        tomadorSeguroContrario: '',
        nifTomadorSeguroContrario: '',
        telefonoTomadorSeguroContrario: '',
        marcaVehiculoContrario: '',
        matriculaVehiculoContrario: '',
        polizaSeguroContrario: '',
        nombreConductorContrario: '',
        direccionConductorContrario: '',
        nifConductorContrario: '',
        telefonoConductorContrario: '',
        observacionesContrario: '',
        descripcion: '',
        condicionesClimatologicas: '',
        condicionesLugar: '',
        condicionesTrafico: '',
      },
      validationSchema: Yup.object({
        fecha: Yup.date().required('Campo requerido').min(moment().format('YYYY-MM-DD'), 'Fecha incorrecta.'),
        lugar: Yup.string().required('campo requerido'),
        descripcion: Yup.string().required('campo requerido'),
      }),
      onSubmit: values => {
        setTimeout(async () => {
          const resp = await Confirm('SE VA ACREAR EL INFORME DE GOLPE. UNA VEZ CREADO, PPODRÁ ADJUNTAR IMAGENES. REVISE LOS DATOS INTRODUCIDOS, UNA VEZ CREADO EL INFORME NO PODRÁ VOLVER ATRÁS. ¿DESEA CONTINUAR?', 'ACEPTAR', 'CANCELAR')
          if (resp) {
            const proceso = 'A_GOLPE_INFORME_CREAR.PRO'
            let params = `?IN_VEHICULO_ID=${values.vehiculoId}`
            params = params + `&IN_CONDUCTOR_ID=${values.conductorId}`
            params = params + `&IN_CUADRANTE_ID=${values.inCuadranteId}`
            params = params + `&IN_INDICATIVO_SERVICIO_ID=${values.inIndicativoServicioId}`
            params = params + `&IN_GOLPE_LUGAR=${values.lugar}`
            params = params + `&IN_ATESTADO_POR=${values.atestado}`
            params = params + `&IN_ASEGURADORA=${values.aseguradoraContrario}`
            params = params + `&IN_ASEGURADO=${values.tomadorSeguroContrario}`
            params = params + `&IN_ASEGURADO_NIF=${values.nifTomadorSeguroContrario}`
            params = params + `&IN_ASEGURADO_TELEFONO=${values.telefonoTomadorSeguroContrario}`
            params = params + `&IN_VEHICULO_CONTRARIO_MARCA_MODELO=${values.marcaVehiculoContrario}`
            params = params + `&IN_VEHICULO_CONTRARIO_MATRICULA=${values.matriculaVehiculoContrario}`
            params = params + `&IN_ASEGURADO_POLIZA=${values.polizaSeguroContrario}`
            params = params + `&IN_CONDUCTOR_CONTRARIO_NOMBRE=${values.nombreConductorContrario}`
            params = params + `&IN_CONDUCTOR_CONTRARIO_DIRECCION=${values.direccionConductorContrario}`
            params = params + `&IN_CONDUCTOR_CONTRARIO_NIF=${values.nifConductorContrario}`
            params = params + `&IN_CONDUCTOR_CONTRARIO_TELEFONO=${values.telefonoConductorContrario}`
            params = params + `&IN_DESCRIPCION_GOLPE=${values.descripcion}`
            params = params + `&IN_OBSERVACIONES_CONTRARIO=${values.observacionesContrario}`
            params = params + `&IN_CONDICIONES_CLIMATOLOGICAS=${values.condicionesClimatologicas}`
            params = params + `&IN_CONDICIONES_LUGAR=${values.condicionesLugar}`
            params = params + `&IN_CONDICIONES_TRAFICO=${values.condicionesTrafico}`
            let url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
            const data = fetchData(url)
            data.then(dat => {
              if (dat.ESTADO_CREACION_GOLPE === '1') {
                document.getElementById('data').classList.add('no-show')
                document.getElementById('data-btn-group').classList.add('no-show')
                document.getElementById('photos').classList.remove('no-show')
                document.getElementById('photos-btn-group').classList.remove('no-show')
                setIdInformeGolpe(dat.GOLPE_ID)
              }
            })
          }
        }, 100);
      }
    }
  )

  const uploadImage = (numFoto, base64Image) => {
    document.getElementById(`pendingUploadedImage${numFoto}`).classList.remove('no-show')
    document.getElementById(`checkUploadedImage${numFoto}`).classList.add('no-show')
    document.getElementById(`errorUploadedImage${numFoto}`).classList.add('no-show')
    const proceso = 'A_GOLPE_ADD_FOTO.PRO'
    let params = `?IN_VEHICULO_ID=${formik.values.vehiculoId}`
    params = params + `&IN_GOLPE_ID=${idInformeGolpe}`
    params = params + `&IN_NUMERO_IMAGEN=${numFoto}`
    params = params + `&IN_IMAGEN_GOLPE=${base64Image}`
    const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
    const foto = fetchData(url, false)
    foto.then(dat => {
      document.getElementById(`pendingUploadedImage${numFoto}`).classList.add('no-show')
      dat.ESTADO_ADD_FOTO === '1' && document.getElementById(`checkUploadedImage${numFoto}`).classList.remove('no-show')
      dat.ESTADO_ADD_FOTO === '0' && document.getElementById(`errorUploadedImage${numFoto}`).classList.remove('no-show')
    })
  }

  const setNuevaImagen = (numImagen, imageUrl) => {
    const nuevaImagenGolpe = imagenGolpe.map(img => {
      if (img.id === numImagen) {
        return { id: numImagen, imagen: imageUrl }
      } else {
        return img
      }
    })
    setImagenGolpe(nuevaImagenGolpe)
  }

  const setImage = async (e) => {
    const idElement = e.target.id
    const image = e.target.files[0]
    const imageUrl = URL.createObjectURL(image)
    const fileSize = formatFileSize(image.size)
    console.log(fileSize)

    const compressedImageInBase64 = await fileToBase64(image)

    switch (idElement) {
      case ('inputFoto1'):
        uploadImage(1, compressedImageInBase64.split(',')[1])
        setNuevaImagen(1, imageUrl)
        break;

      case ('inputFoto2'):
        uploadImage(2, compressedImageInBase64.split(',')[1])
        setNuevaImagen(2, imageUrl)
        break;

      case ('inputFoto3'):
        uploadImage(3, compressedImageInBase64.split(',')[1])
        setNuevaImagen(3, imageUrl)
        break;

      case ('inputFoto4'):
        uploadImage(4, compressedImageInBase64.split(',')[1])
        setNuevaImagen(4, imageUrl)
        break;

      case ('inputFoto5'):
        uploadImage(5, compressedImageInBase64.split(',')[1])
        setNuevaImagen(5, imageUrl)
        break;

      case ('inputFoto6'):
        uploadImage(6, compressedImageInBase64.split(',')[1])
        setNuevaImagen(6, imageUrl)
        break;

      case ('inputFoto1FromCamera'):
        uploadImage(1, compressedImageInBase64.split(',')[1])
        setNuevaImagen(1, imageUrl)
        break;

      case ('inputFoto2FromCamera'):
        uploadImage(2, compressedImageInBase64.split(',')[1])
        setNuevaImagen(2, imageUrl)
        break;

      case ('inputFoto3FromCamera'):
        uploadImage(3, compressedImageInBase64.split(',')[1])
        setNuevaImagen(3, imageUrl)
        break;

      case ('inputFoto4FromCamera'):
        uploadImage(4, compressedImageInBase64.split(',')[1])
        setNuevaImagen(4, imageUrl)
        break;

      case ('inputFoto5FromCamera'):
        uploadImage(5, compressedImageInBase64.split(',')[1])
        setNuevaImagen(5, imageUrl)
        break;

      case ('inputFoto6FromCamera'):
        uploadImage(6, compressedImageInBase64.split(',')[1])
        setNuevaImagen(6, imageUrl)
        break;

      default:
        break;
    }
  }

  const getImageFromCamera = (e) => {
    e.target.id === 'btnFoto1' && document.getElementById('inputFoto1FromCamera').click()
    e.target.id === 'btnFoto2' && document.getElementById('inputFoto2FromCamera').click()
    e.target.id === 'btnFoto3' && document.getElementById('inputFoto3FromCamera').click()
    e.target.id === 'btnFoto4' && document.getElementById('inputFoto4FromCamera').click()
    e.target.id === 'btnFoto5' && document.getElementById('inputFoto5FromCamera').click()
    e.target.id === 'btnFoto6' && document.getElementById('inputFoto6FromCamera').click()
  }

  const handleCopiarDatosTomador = (e) => {
    if (e.target.checked) {
      document.getElementById('nombreConductorContrario').value = document.getElementById('tomadorSeguroContrario').value
      document.getElementById('nifConductorContrario').value = document.getElementById('nifTomadorSeguroContrario').value
      document.getElementById('telefonoConductorContrario').value = document.getElementById('telefonoTomadorSeguroContrario').value
      formik.values.nombreConductorContrario = formik.values.tomadorSeguroContrario
      formik.values.nifConductorContrario = formik.values.nifTomadorSeguroContrario
      formik.values.telefonoConductorContrario = formik.values.telefonoTomadorSeguroContrario
    } else {
      document.getElementById('nombreConductorContrario').value = ''
      document.getElementById('nifConductorContrario').value = ''
      document.getElementById('telefonoConductorContrario').value = ''
      formik.values.nombreConductorContrario = ''
      formik.values.nifConductorContrario = ''
      formik.values.telefonoConductorContrario = ''
    }
  }

  return (
    <>
      <div className='mb-3 px-3'>
        <form onSubmit={formik.handleSubmit} id="informeDeGolpe">
          <div id="data">
            <input type="hidden" onChange={formik.handleChange} value={formik.values.conductorId} />
            <input type="hidden" onChange={formik.handleChange} value={formik.values.vehiculoId} />
            <input type="hidden" onChange={formik.handleChange} value={formik.values.inIndicativoServicioId} />
            <input type="hidden" onChange={formik.handleChange} value={formik.values.inCuadranteId} />

            <h4>MIS DATOS</h4>
            <div className='row'>
              <div className="col-sm-2">
                <label htmlFor='fecha' className='form-label'>Fecha</label>
                <div className="input-group mb-3">
                  <input
                    type="date"
                    className={`form-control form-control-sm ${(formik.touched.fecha && formik.errors.fecha) && 'form-control-error'}`}
                    id='fecha'
                    name="fecha"
                    value={formik.values.fecha}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="col-sm-2">
                <label htmlFor='matricula' className='form-label'>Matrícula</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className='form-control form-control-sm'
                    id='matricula'
                    name="matricula"
                    value={formik.values.matricula}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-2">
                <label htmlFor='conductor' className='form-label'>Conductor</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className='form-control form-control-sm'
                    id='conductor'
                    name="conductor"
                    value={formik.values.conductor}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-6 mb-3">
                <label htmlFor="atestado" className='form-label'>Atestado</label>
                <input
                  type="text"
                  name="atestado"
                  id="atestado"
                  className={`form-control form-control-sm ${(formik.touched.atestado && formik.errors.atestado) && 'form-control-error'}`}
                  value={formik.values.atestado}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="row">
              <div className="col mb-3">
                <label htmlFor="lugar" className='form-label'>Lugar</label>
                <input
                  type="text"
                  name="lugar"
                  id="lugar"
                  className={`form-control form-control-sm ${(formik.touched.lugar && formik.errors.lugar) && 'form-control-error'}`}
                  value={formik.values.lugar}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            <div className="row">
              <div className="col mb-3">
                <label htmlFor="descripcion" className='form-label'>Descripción del golpe</label>
                <textarea
                  name="descripcion"
                  id="descripcion"
                  cols="30"
                  rows="10"
                  value={formik.values.descripcion}
                  className={`form-control form-control-sm ${(formik.touched.descripcion && formik.errors.descripcion) && 'form-control-error'}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                </textarea>
              </div>
            </div>

            <div className="row">
              <div className="col mb-3">
                <label htmlFor="condicionesClimatologicas">Condiciones Climatológicas relevantes para el accidente</label>
                <input
                  type="text"
                  className={`form-control form-control-sm ${(formik.touched.condicionesClimatologicas && formik.errors.condicionesClimatologicas) && 'form-control-error'}`}
                  name="condicionesClimatologicas"
                  id="condicionesClimatologicas"
                  value={formik.values.condicionesClimatologicas}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            <div className="row">
              <div className="col mb-3">
                <label htmlFor="condicionesLugar">Condiciones del lugar del accidente relevantes para el accidente</label>
                <input
                  type="text"
                  className={`form-control form-control-sm ${(formik.touched.condicionesLugar && formik.errors.condicionesLugar) && 'form-control-error'}`}
                  name="condicionesLugar"
                  id="condicionesLugar"
                  value={formik.values.condicionesLugar}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            <div className="row">
              <div className="col mb-3">
                <label htmlFor="condicionesTrafico">Condiciones del tráfico relevantes para el accidente</label>
                <input
                  type="text"
                  className={`form-control form-control-sm ${(formik.touched.condicionesTrafico && formik.errors.condicionesTrafico) && 'form-control-error'}`}
                  name="condicionesTrafico"
                  id="condicionesTrafico"
                  value={formik.values.condicionesTrafico}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <hr className='mt-5 mb-5' />
            <h4>DATOS DEL TOMADOR DEL SEGURO CONTRARIO</h4>
            <div className='row mb-3'>
              <div className='col-sm-6 mb-3'>
                <label htmlFor='aseguradoraContrario'>Compañia aseguradora</label>
                <input
                  type='text'
                  className='form-control form-control-sm'
                  name='aseguradoraContrario'
                  id='aseguradoraContrario'
                  value={formik.values.aseguradoraContrario}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className='col-sm-6 mb-3'>
                <label htmlFor='polizaSeguroContrario'>Póliza</label>
                <input
                  type='text'
                  className='form-control form-control-sm'
                  name='polizaSeguroContrario'
                  id='polizaSeguroContrario'
                  value={formik.values.polizaSeguroContrario}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-sm-6 mb-3'>
                <label htmlFor='tomadorSeguroContrario'>Tomador del seguro</label>
                <input
                  type='text'
                  className='form-control form-control-sm'
                  name='tomadorSeguroContrario'
                  id='tomadorSeguroContrario'
                  value={formik.values.tomadorSeguroContrario}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className='col-sm-3 mb-3'>
                <label htmlFor='nifTomadorSeguroContrario'>NIF</label>
                <input
                  type='text'
                  className='form-control form-control-sm'
                  name='nifTomadorSeguroContrario'
                  id='nifTomadorSeguroContrario'
                  value={formik.values.nifTomadorSeguroContrario}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className='col-sm-3 mb-3'>
                <label htmlFor='telefonoTomadorSeguroContrario'>Teléfono</label>
                <input
                  type='text'
                  className='form-control form-control-sm'
                  name='telefonoTomadorSeguroContrario'
                  id='telefonoTomadorSeguroContrario'
                  value={formik.values.telefonoTomadorSeguroContrario}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-sm-9 mb-3'>
                <label htmlFor='marcaVehiculoContrario'>Marca y modelo del vehículo contrario</label>
                <input
                  type='text'
                  className='form-control form-control-sm'
                  name='marcaVehiculoContrario'
                  id='marcaVehiculoContrario'
                  value={formik.values.marcaVehiculoContrario}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className='col-sm-3 mb-3'>
                <label htmlFor='matriculaVehiculoContrario'>Matrícula</label>
                <input
                  type='text'
                  className='form-control form-control-sm'
                  name='matriculaVehiculoContrario'
                  id='matriculaVehiculoContrario'
                  value={formik.values.matriculaVehiculoContrario}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            <hr className='mt-5 mb-5' />
            <h4>DATOS DEL CONDUCTOR DEL VEHÍCULO CONTRARIO</h4>
            <div className="form-check my-2">
              <input className="form-check-input" type="checkbox" value='' id="copiarDatosTomador" onChange={handleCopiarDatosTomador} />
              <label className="form-check-label" htmlFor="copiarDatosTomador">
                Copiar datos del Tomador del seguro.
              </label>
            </div>
            <div className='row'>
              <div className='col-sm-6 mb-2'>
                <label htmlFor='nombreConductorContrario'>Nombre y apellidos</label>
                <input
                  type='text'
                  className={`form-control form-control-sm`}
                  id='nombreConductorContrario'
                  name='nombreConductorContrario'
                  value={formik.values.nombreConductorContrario}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className='col-sm-3 mb-2'>
                <label htmlFor='nifConductorContrario'>NIF</label>
                <input
                  type='text'
                  className={`form-control form-control-sm`}
                  id='nifConductorContrario'
                  name='nifConductorContrario'
                  value={formik.values.nifConductorContrario}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className='col-sm-3 mb-2'>
                <label htmlFor='telefonoConductorContrario'>Teléfono</label>
                <input
                  type='text'
                  className={`form-control form-control-sm`}
                  id='telefonoConductorContrario'
                  name='telefonoConductorContrario'
                  value={formik.values.telefonoConductorContrario}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col mb-2'>
                <label htmlFor='direccionConductorContrario'>Dirección</label>
                <input
                  type='text'
                  className={`form-control form-control-sm`}
                  id='direccionConductorContrario'
                  name='direccionConductorContrario'
                  value={formik.values.direccionConductorContrario}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col mb-2'>
                <label htmlFor='observacionesContrario'>Observaciones sobre el conductor contrario</label>
                <textarea
                  type='text'
                  className={`form-control form-control-sm`}
                  id='observacionesContrario'
                  name='observacionesContrario'
                  cols="30"
                  rows="10"
                  value={formik.values.observacionesContrario}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                </textarea>
              </div>
            </div>
          </div>

          <div id="photos" className='no-show'>
            <h4>IMAGENES</h4>


            {
              imagenGolpe.map((ft, index) => {
                return (
                  <div key={index} className="card mb-3">
                    <div className="row g-0">
                      <div className="col-md-8">
                        <div className="card-body d-flex flex-column">
                          <div className='d-flex'>
                            <div className='me-auto'>
                              <span id={`pendingUploadedImage${ft.id}`} className="px-3 py-2 badge text-bg-primary">PENDIENTE</span>
                              <span id={`checkUploadedImage${ft.id}`} className="px-3 py-2 badge text-bg-success no-show">CORRECTO</span>
                              <span id={`errorUploadedImage${ft.id}`} className="px-3 py-2  badge text-bg-danger no-show">ERROR</span>
                            </div>
                            <div className='mb-1'>
                              <h5 className="card-title">{`FOTO ${ft.id !== 6 ? ft.id : ' CROQUIS/PARTE AMISTOSO'}`}</h5>
                            </div>
                          </div>
                          <div className='mb-3'>
                            <input
                              type='file'
                              className={`form-control form-control-sm`}
                              id={`inputFoto${ft.id}`}
                              name={`inputFoto${ft.id}`}
                              accept='image/jpeg'
                              onChange={setImage}
                            />
                            <div className='text-center mt-3'>
                              <button type="button" className='btn btn-primary' id={`btnFoto${ft.id}`} onClick={getImageFromCamera}>CAPTURAR FOTO</button>
                            </div>
                            <input
                              type='file'
                              className={`form-control form-control-sm hide`}
                              id={`inputFoto${ft.id}FromCamera`}
                              name={`inputFoto${ft.id}FromCamera`}
                              capture="camera"
                              accept='image/jpeg'
                              onChange={setImage}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <img src={imagenGolpe[ft.id - 1].imagen} id={`imgFoto${ft.id}`} className="img-fluid rounded mx-auto d-block" alt="imagen golpe" />
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </form>
      </div >
      <div className='text-end mt-auto pt-3 px-3'>
        <div id="data-btn-group">
          <NavLink to='/registros' className='btn btn-primary me-1'>CANCELAR</NavLink>
          <button type='submit' form='informeDeGolpe' className='btn btn-primary'>SIGUIENTE</button>
        </div>

        <div id="photos-btn-group" className='no-show'>
          <NavLink to='/registros' className='btn btn-primary me-1'>FINALIZAR</NavLink>
        </div>
      </div>
    </>
  )
}
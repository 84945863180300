import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { modificarConceptoRegistroCalidad } from '../redux/actions/calidadActions'

export const CalidadConcepto = ({ pregunta, registroCalidadId }) => {
  const dispatch = useDispatch()
  let fechaCaducidad = moment().format('YYYY-MM-DD')

  if (pregunta.IN_PREGUNTA_ES_FECHACADUCIDAD === 'SI' && pregunta.IN_VALOR_FECHACADUCIDAD !== '') {
    fechaCaducidad = moment(pregunta.IN_VALOR_FECHACADUCIDAD).format('YYYY-MM-DD')
  }

  const [inputField, setInputField] = useState({
    [pregunta.IN_CODIGO_PREGUNTA_ID + '-fecha']: fechaCaducidad,
    [pregunta.IN_CODIGO_PREGUNTA_ID + '-cantidad']: pregunta.IN_VALORNUMERICO,
    [pregunta.IN_CODIGO_PREGUNTA_ID + '-sino']: pregunta.IN_VALOR_SINO,
    registroCalidadId: registroCalidadId,
    preguntaId: pregunta.IN_CODIGO_PREGUNTA_ID,
    esFechaCaducidad: pregunta.IN_PREGUNTA_ES_FECHACADUCIDAD,
    esValorNumerico: pregunta.IN_PREGUNTA_ES_VALORNUMERICO,
    esSiNo: pregunta.IN_PREGUNTA_ES_SINO
  })

  const handleInputChange = (e) => {
    e.target.type === 'number' && setInputField(inputField => { return { ...inputField, [e.target.name]: e.target.value } })
    e.target.type === 'date' && setInputField(inputField => { return { ...inputField, [e.target.name]: e.target.value } })
    e.target.type === 'radio' && setInputField(inputField => { return { ...inputField, [e.target.name]: e.target.value } })
  }

  const handleSubmit = (e) => {
    document.getElementById(`${pregunta.IN_CODIGO_PREGUNTA_ID}-spinner`).classList.remove('no-show')
    document.getElementById(`${pregunta.IN_CODIGO_PREGUNTA_ID}-submit`).classList.add('no-show')
    e.preventDefault()
    dispatch(modificarConceptoRegistroCalidad(inputField))
    setTimeout(() => {
      document.getElementById(`${pregunta.IN_CODIGO_PREGUNTA_ID}-spinner`).classList.add('no-show')
      document.getElementById(`${pregunta.IN_CODIGO_PREGUNTA_ID}-submit`).classList.remove('no-show')
    }, 1000)
  }

  return (
    <div className='card custom-card mt-4'>
      <div className='card-header card-header-primary'>
        <div className='d-flex fw-bold'>
          <div className='me-auto'>{pregunta.IN_PREGUNTA_NAME}</div>
          <div>{``}</div>
        </div>
      </div>
      <form id={pregunta.IN_CODIGO_PREGUNTA_ID} onSubmit={handleSubmit} className='row gx-3 gy-2 align-items-center'>
        <input type='hidden' name='registroCalidadId' value={inputField.registroCalidadId} />
        <input type='hidden' name='preguntaId' value={inputField.preguntaId} />
        <input type='hidden' name='esFechaCaducidad' value={inputField.esFechaCaducidad} />
        <input type='hidden' name='esValorNumerico' value={inputField.esValorNumerico} />
        <input type='hidden' name='esSiNo' value={inputField.esSiNo} />
        <div className='card-body row mb-3'>
          {
            pregunta.IN_PREGUNTA_ES_SINO === 'SI' &&
            <div className='col-sm-4'>
              <label htmlFor={`${pregunta.IN_CODIGO_PREGUNTA_ID}-sino`} className='form-label'>Existencias (Si/No)</label>
              <div className='d-flex'>
                <div className='form-check me-3'>
                  <label htmlFor={`${pregunta.IN_CODIGO_PREGUNTA_ID}-sino`} className='form-check-label'>Si</label>
                  <input
                    type='radio'
                    className='form-check-input'
                    id={`${pregunta.IN_CODIGO_PREGUNTA_ID}-sino-1`}
                    name={`${pregunta.IN_CODIGO_PREGUNTA_ID}-sino`}
                    value='SI'
                    onChange={handleInputChange}
                    checked={inputField[pregunta.IN_CODIGO_PREGUNTA_ID + '-sino'] === 'SI' ? true : false}
                  />
                </div>
                <div className='form-check'>
                  <label htmlFor={`${pregunta.IN_CODIGO_PREGUNTA_ID}-sino`} className='form-check-label'>No</label>
                  <input
                    type='radio'
                    className='form-check-input'
                    id={`${pregunta.IN_CODIGO_PREGUNTA_ID}-sino-2`}
                    name={`${pregunta.IN_CODIGO_PREGUNTA_ID}-sino`}
                    value='NO'
                    onChange={handleInputChange}
                    checked={inputField[pregunta.IN_CODIGO_PREGUNTA_ID + '-sino'] === 'NO' ? true : false}
                  />
                </div>
              </div>
            </div>
          }
          {
            pregunta.IN_PREGUNTA_ES_VALORNUMERICO === 'SI' &&
            <div className='col-sm-4'>
              <label htmlFor={`${pregunta.IN_CODIGO_PREGUNTA_ID}-cantidad`} className='form-label'>Cantidad</label>
              <input
                type='number'
                className='form-control form-control-sm'
                id={`${pregunta.IN_CODIGO_PREGUNTA_ID}-cantidad`}
                name={`${pregunta.IN_CODIGO_PREGUNTA_ID}-cantidad`}
                value={inputField[pregunta.IN_CODIGO_PREGUNTA_ID + '-cantidad']}
                onChange={handleInputChange}
              />
            </div>
          }
          {
            pregunta.IN_PREGUNTA_ES_FECHACADUCIDAD === 'SI' &&
            <div className='col-sm-4'>
              <label htmlFor={`${pregunta.IN_CODIGO_PREGUNTA_ID}-fecha`} className='form-label'>Caducidad</label>
              <input
                type='date'
                className='form-control form-control-sm'
                id={`${pregunta.IN_CODIGO_PREGUNTA_ID}-fecha`}
                name={`${pregunta.IN_CODIGO_PREGUNTA_ID}-fecha`}
                onChange={handleInputChange}
                value={inputField[pregunta.IN_CODIGO_PREGUNTA_ID + '-fecha']}
              />
            </div>

          }
        </div>
      </form>
      <div className='card-footer'>
        <div className='d-flex'>
          <div className='me-auto'></div>
          <div>
            <button className='btn btn-primary btn-sm' type='submit' id={`${pregunta.IN_CODIGO_PREGUNTA_ID}-submit`} form={pregunta.IN_CODIGO_PREGUNTA_ID}>ENVIAR</button>
            <button className='btn btn-primary btn-sm no-show' type='button' id={`${pregunta.IN_CODIGO_PREGUNTA_ID}-spinner`}>
              <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
              ENVIANDO...
            </button>
          </div>
        </div>
      </div>

    </div>
  )
}
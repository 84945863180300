import React from 'react'
import uniqid from 'uniqid'

export const ChangeLogModal = () => {

  const changelogJson = require('../changelog.json')

  const closeModal = () => {
    const element = document.getElementById('modal-container')
    element.parentNode.removeChild(element)
  }

  return (
    <div className='modal-card-container d-flex justify-content-center align-items-center'>
      <div className='changelog-modal card modal-card animate__animated animate__fadeIn'>
        <div className='card-header card-header-primary'>GIA HISTORIAL DE CAMBIOS</div>
        <span className='ms-3'><strong>VELNEO API:</strong> {process.env.REACT_APP_APIVEL}</span>
        <div className='changelog-modal-body card-body'>
          {
            changelogJson.map(chlg => {
              return (
                <div className='changelog-modal-text-block mb-3' key={uniqid()}>
                  <p><span className='fw-bold'>Título: </span>{chlg.titulo}</p>
                  <p><span className='fw-bold'>Fecha: </span>{chlg.fecha}</p>
                  <p><span className='fw-bold'>Versión: </span>{chlg.version}</p>
                  <p><span className='fw-bold'>Descripción: </span>{chlg.descripcion}</p>
                </div>
              )
            })
          }
        </div>
        <div className='card-footer'>
          <div className='text-end'>
            <button className='btn btn-primary' onClick={closeModal}>ACEPTAR</button>
          </div>
        </div>
      </div>
    </div>
  )
}
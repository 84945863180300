import { types } from '../types'

const initialValues = {
  respuestaSolicitudCambioTurno: '',
  datosCubreTurno: '',
  datosSolicitante: ''
}

export const cambioTurnoReducer = (state = initialValues, action) => {
  switch (action.type) {
    case types.solicitarCambioturno:
      return {
        ...state,
        respuestaSolicitudCambioTurno: action.payload.respuestaSolicitudCambioTurno,
        datosCubreTurno: action.payload.datosCubreTurno,
        datosSolicitante: action.payload.datosSolicitante
      }
    default:
      return state
  }
}
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { NavLink, useNavigate } from 'react-router-dom'
import { enviarDatosSolicitudCambioVehiculo, cambioVehiculo } from '../redux/actions/cambioVehiculoActions'
import * as Yup from 'yup'
import { Confirm } from '../helpers/Confirm'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'

export const VehiculoCambio = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { vehiculoMatricula } = useSelector(({ loginReducer }) => loginReducer)
  const { respuestaCambioVehiculo, mensajeCambioVehiculo } = useSelector(({ cambioVehiculoReducer }) => cambioVehiculoReducer)

  const formik = useFormik({
    initialValues: {
      vehiculoMatricula: vehiculoMatricula,
      vehiculoNuevoMatricula: '',
      vehiculoNuevoKms: ''
    },
    validationSchema: Yup.object(
      {
        vehiculoNuevoMatricula: Yup.string().required('Campo requerido').matches(/^[0-9]{4}-[A-Z|a-z]{3}$/, 'Formato incorrecto. Debe tener un formato tipo 0000-AAA'),
        vehiculoNuevoKms: Yup.string().required('Campo requerido').matches(/^[0-9]+([0-9]+)?$/, 'Formato incorrecto.'),
      }
    ),
    onSubmit: values => {
      setTimeout(async () => {
        const resp = await Confirm('SE VA AREALIZAR UN CAMBIO DE VEHÍCULO. ¿DESEA CONTINUAR?', 'ACEPTAR', 'CANCELAR')
        if (resp) {
          dispatch(enviarDatosSolicitudCambioVehiculo(values))
        }
      }, 100)
    },
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    formik.setFieldValue(name, value.toUpperCase())
  }

  useEffect(() => {
    return () => {
      dispatch(cambioVehiculo({ ERROR: '', MENSAJE_ERROR: '' }))
    }
  }, [dispatch])


  useEffect(() => {
    respuestaCambioVehiculo === '0'
      ?
      setTimeout(async () => {
        const resp = await Confirm('CAMBIO DE VEHICULO REALIZADO CORRECTAMENTE. DEBE INICIAR JORNADA CON LOS NUEVOS DATOS', 'ACEPTAR')
        if (resp) {
          navigate('/')
        }
      }, 100)
      :
      (respuestaCambioVehiculo !== '' && respuestaCambioVehiculo !== '0')
      &&
      document.querySelector('#alert').classList.remove('no-show')
  }, [respuestaCambioVehiculo, mensajeCambioVehiculo, navigate, dispatch])


  return (
    <PageLayout>
      <div className='content d-flex flex-column'>
        <TitlePage title='CAMBIO DE VEHÍCULO' />
        <div className='mb-3 px-3'>
          <form onSubmit={formik.handleSubmit} id='cambioVehiculo'>

            <div className='row mb-3'>
              <div className='col-sm-6'>
                <label htmlFor='vehiculoMatricula' className='form-label'>Matrícula del vehículo actual</label>
                <input
                  className='form-control form-control-sm'
                  type='text'
                  name='vehiculoMatricula'
                  id='vehiculoMatricula'
                  value={formik.values.vehiculoMatricula}
                  disabled
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-sm-6'>
                <label htmlFor='vehiculoNuevoMatricula' className='form-label'>Matrícula del vehículo nuevo</label>
                <input
                  className={`form-control form-control-sm ${(formik.touched.vehiculoNuevoMatricula && formik.errors.vehiculoNuevoMatricula) && 'form-control-error'}`}
                  type='text'
                  name='vehiculoNuevoMatricula'
                  id='vehiculoNuevoMatricula'
                  value={formik.values.vehiculoNuevoMatricula}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className='col-sm-6'>
                <label htmlFor='vehiculoNuevoKms' className='form-label'>Kilómetros del vehículo nuevo</label>
                <input
                  className={`form-control form-control-sm ${(formik.touched.vehiculoNuevoKms && formik.errors.vehiculoNuevoKms) && 'form-control-error'}`}
                  type='text'
                  name='vehiculoNuevoKms'
                  id='vehiculoNuevoKms'
                  value={formik.values.vehiculoNuevoKms}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

          </form >
        </div>
        <div className='text-end mt-auto pt-3 px-3'>
          <div className='alert alert-primary text-center no-show' id='alert' role='alert'>
            {
              mensajeCambioVehiculo.toUpperCase()
            }
          </div>
          <NavLink to='/' className='btn btn-primary me-1'>CANCELAR</NavLink>
          <button type='submit' form='cambioVehiculo' className='btn btn-primary'>ACEPTAR</button>
        </div>
      </div>
    </PageLayout>
  )
}

import { types } from '../types'
import { fetchData } from '../fetchData'

export const obtenerListaRepostajes = (inVehiculoId) => {
  const proceso = 'A_REPOSTAJES_BUSCAR.PRO'
  let params = `?IN_VEHICULO_ID=${inVehiculoId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return ((dispatch) => { data.then(data => { dispatch(listaRepostajes(data.LISTA_REPOSTAJES)) }) })
}

export const obtenerDatosSolicitudNuevoRepostaje = () => {
  const proceso = 'A_REPOSTAJE_NUEVO_SOLICITUD_DATOS.PRO'
  const url = `${process.env.REACT_APP_APIVEL}${proceso}`
  const data = fetchData(url)
  return ((dispatch) => { data.then(data => { dispatch(solicitudDatosNuevoRepostaje(data)) }) })
}

export const enviarDatosNuevoRepostaje = ({
  codigoTarjeta,
  codigoTicket,
  conductorId,
  fechaRepostaje,
  gasolinera,
  importe,
  indicativoServicioId,
  litros,
  tipoCombustible,
  vehiculoId,
  vehiculoKms,
  vehiculoMatricula,
  base64File
}) => {
  const proceso = 'A_REPOSTAJE_NUEVO_CREAR.PRO'
  let params = `?IN_CODIGO_TARJETA=${codigoTarjeta}`
  params = params + `&IN_CODIGO_TICKET=${codigoTicket}`
  params = params + `&IN_CONDUCTOR_ID=${conductorId}`
  params = params + `&IN_FECHA_REPOSTAJE=${fechaRepostaje}`
  params = params + `&IN_GASOLINERA_ID=${gasolinera}`
  params = params + `&IN_IMPORTE=${importe}`
  params = params + `&IN_INDICATIVO_SERVICIO_ID=${indicativoServicioId}`
  params = params + `&IN_LITROS=${litros}`
  params = params + `&IN_TIPO_COMBUSTIBLE_ID=${tipoCombustible}`
  params = params + `&IN_VEHICULO_ID=${vehiculoId}`
  params = params + `&IN_VEHICULO_KMS=${vehiculoKms}`
  params = params + `&IN_VEHICULO_MATRICULA=${vehiculoMatricula}`
  params = params + `&IN_IMAGEN_TICKET=${base64File}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return ((dispatch) => { data.then(data => { dispatch(nuevoRepostaje(data)) }) })
}

export const obtenerDatosRepostaje = (vehiculoId, repostajeId) => {
  const proceso = 'A_REPOSTAJE_VER.PRO'
  let params = `?IN_VEHICULO_ID=${vehiculoId}`
  params = params + `&IN_REPOSTAJE_ID=${repostajeId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return ((dispatch) => {
    data.then(data => {
      console.log(data.REPOSTAJE_IMAGEN_TICKET)
      dispatch(verRepostaje(data))
    })
  })
}

export const enviarDatosModificarRepostaje = ({
  vehiculoId,
  repostajeId,
  codigoTarjeta,
  codigoTicket,
  fechaRepostaje,
  gasolinera,
  importe,
  litros,
  tipoCombustible,
  vehiculoKms,
  base64File
}) => {
  const proceso = 'A_REPOSTAJE_MODIFICAR_DATOS.PRO'
  let params = `?IN_VEHICULO_ID=${vehiculoId}`
  params = params + `&IN_REPOSTAJE_ID=${repostajeId}`
  params = params + `&IN_CODIGO_TARJETA=${codigoTarjeta}`
  params = params + `&IN_CODIGO_TICKET=${codigoTicket}`
  params = params + `&IN_FECHA_REPOSTAJE=${fechaRepostaje}`
  params = params + `&IN_GASOLINERA_ID=${gasolinera}`
  params = params + `&IN_IMPORTE=${importe}`
  params = params + `&IN_LITROS=${litros}`
  params = params + `&IN_TIPO_COMBUSTIBLE_ID=${tipoCombustible}`
  params = params + `&IN_VEHICULO_KMS=${vehiculoKms}`
  params = params + `&IN_IMAGEN_TICKET=${base64File}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return ((dispatch) => { data.then(data => { dispatch(modificarRepostaje(data)) }) })
}



export const listaRepostajes = (data) => {
  return {
    type: types.listarRepostajes,
    payload: {
      listaRepostajes: data
    }
  }
}

export const solicitudDatosNuevoRepostaje = (data) => {
  return {
    type: types.solicitudDatosNuevoRepostaje,
    payload: {
      listaAreas: data.LISTA_AREAS,
      listaGasolineras: data.LISTA_GASOLINERAS,
      listaTiposCombustible: data.LISTA_TIPOS_COMBUSTIBLE
    }
  }
}

export const nuevoRepostaje = (data) => {
  return {
    type: types.nuevoRepostaje,
    payload: {
      respuestaNuevoRepostaje: data.ESTADO_NUEVO_REPOSTAJE.toString(),
      mensajeNuevoRepostaje: data.MENSAJE_ERROR
    }
  }
}

export const verRepostaje = (data) => {
  return {
    type: types.verRepostaje,
    payload: {
      repostaje: data
    }
  }
}

export const modificarRepostaje = (data) => {
  return {
    type: types.modificarRepostaje,
    payload: {
      respuestaModificarRepostaje: data.ESTADO_REPOSTAJE_MODIFICADO.toString(),
      mensajeModificarRepostaje: data.MENSAJE_ERROR
    }
  }
}
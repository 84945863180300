import React from 'react'
import ReactDOM from 'react-dom/client'
import { ServicioFotosModal } from '../components/ServicioFotosModal'

export const ServicioFotos = ({ servicio }) => {
  return new Promise((resolve, reject) => {
    showModal(resolve, reject, servicio)
  })
}

const showModal = (resolve, reject, servicio) => {
  const body = document.getElementsByTagName('body')[0]
  const div = document.createElement('div')
  div.setAttribute('id', 'modal-container')
  body.appendChild(div)
  const modalContainer = ReactDOM.createRoot(div)
  modalContainer.render(
    <ServicioFotosModal
      resolve={resolve}
      reject={reject}
      servicio={servicio}
    />
  )
}
import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import 'animate.css'

export const ServicioInformacionModal = ({ resolve, reject, servicio }) => {
  console.log('ServicioInformacionModal', servicio)
  const accept = (values) => {
    resolve(values)
    closeModal()
  }

  const cancel = () => {
    resolve('')
    closeModal()
  }

  const closeModal = () => {
    const element = document.getElementById('modal-container')
    element.parentNode.removeChild(element)
  }

  const esUrgencia = servicio.SERVICIO_ES_URGENCIA

  const formik = useFormik({
    initialValues: {
      paciente: servicio.SERVICIO_PACIENTE,
      origen: servicio.SERVICIO_ORIGEN,
      destino: servicio.SERVICIO_DESTINO,
      nss: servicio.SERVICIO_NSS,
      dni: servicio.SERVICIO_DNI,
      observaciones: servicio.SERVICIO_OBSERVACIONES,
      anio: servicio.IN_ANIO,
      idServicio: servicio.IN_SERVICIO_ID,
      tabla: servicio.TABLA,
      marcarComoSecundario: servicio.TIPO_SERVICIO_ID === 8 ? true : false
    },
    validationSchema: Yup.object({
      // No se valida nada
    }),
    onSubmit: (values) => {
      accept(values)
    },
  })


  return (
    <div className='modal-card-container d-flex justify-content-center align-items-center'>
      <div className='card modal-card animate__animated animate__fadeIn'>
        <div className='card-header card-header-primary'>INFORMACION SERVICIO</div>
        <div className='card-body'>
          <form id='informacionServicioForm' onSubmit={formik.handleSubmit}>
            <input
              type='hidden'
              name='anio'
              value={formik.values.anio}
              onChange={formik.handleChange}
            />
            <input
              type='hidden'
              name='idServicio'
              value={formik.values.idServicio}
              onChange={formik.handleChange}
            />
            <input
              type='hidden'
              name='tabla'
              value={formik.values.tabla}
              onChange={formik.handleChange}
            />

            <div className='row mb-3'>
              <div className='col'>
                <label htmlFor='paciente' className='form-label'>
                  Paciente
                </label>
                <input
                  type='text'
                  className={`form-control form-control-sm ${formik.touched.paciente &&
                    formik.errors.paciente &&
                    'input-border-error'
                    }`}
                  id='paciente'
                  name='paciente'
                  value={formik.values.paciente}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={esUrgencia === '0' ? true : false}
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col'>
                <label htmlFor='origen' className='form-label'>
                  Origen
                </label>
                <input
                  type='text'
                  className={`form-control form-control-sm ${formik.touched.origen &&
                    formik.errors.origen &&
                    'input-border-error'
                    }`}
                  id='origen'
                  name='origen'
                  value={formik.values.origen}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={esUrgencia === '0' ? true : false}
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col'>
                <label htmlFor='DESTINO' className='form-label'>
                  Destino
                </label>
                <input
                  type='text'
                  className={`form-control form-control-sm ${formik.touched.origen &&
                    formik.errors.origen &&
                    'input-border-error'
                    }`}
                  id='destino'
                  name='destino'
                  value={formik.values.destino}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={esUrgencia === '0' ? true : false}
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-sm-6'>
                <label htmlFor='nss' className='form-label'>
                  NSS
                </label>
                <input
                  type='text'
                  className={`form-control form-control-sm ${formik.touched.nss &&
                    formik.errors.nss &&
                    'input-border-error'
                    }`}
                  id='nss'
                  name='nss'
                  value={formik.values.nss}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={esUrgencia === '0' ? true : false}
                />
              </div>
              <div className='col-sm-6'>
                <label htmlFor='nss' className='form-label'>
                  DNI
                </label>
                <input
                  type='text'
                  className={`form-control form-control-sm ${formik.touched.dni &&
                    formik.errors.dni &&
                    'input-border-error'
                    }`}
                  id='dni'
                  name='dni'
                  value={formik.values.dni}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={esUrgencia === '0' ? true : false}
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col'>
                <label htmlFor='observaciones' className='form-label'>
                  Observaciones
                </label>
                <textarea
                  className={`form-control form-control-sm ${formik.touched.observaciones &&
                    formik.errors.observaciones &&
                    'input-border-error'
                    }`}
                  id='observaciones'
                  name='observaciones'
                  value={formik.values.observaciones}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  cols='30'
                  rows='10'
                ></textarea>
              </div>
            </div>
            {
              (servicio.TIPO_SERVICIO_ID === 1 || servicio.TIPO_SERVICIO_ID === 2)
              &&
              <div className='row mb-3'>
                <div className='col'>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="marcarComoSecundario"
                      name='marcarComoSecundario'
                      value={formik.values.marcarComoSecundario}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.marcarComoSecundario}
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Marcar servicio como secundario
                    </label>
                  </div>
                </div>
              </div>
            }

          </form>
        </div>
        <div className='card-footer text-end'>
          <button className='btn btn-primary me-1' onClick={cancel}>CANCELAR</button>
          <button className='btn btn-primary' form='informacionServicioForm' type='submit'>ACEPTAR</button>
        </div>
      </div>
    </div>
  )
}

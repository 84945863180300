import { types } from '../types';

const initialValues = {
  listaCitasTaller: [],
  citaTaller: {},
  listaTrabajosSolicitadosCitaTaller: [],
  listaTrabajosRealizadosCitaTaller: []
}

export const citasTallerReducer = (state = initialValues, action) => {
  switch (action.type) {
    case types.listarCitasTaller:
      return {
        ...state,
        listaCitasTaller: action.payload.listaCitasTaller
      }
    case types.verCitaTaller:
      return {
        ...state,
        citaTaller: action.payload.citaTaller,
        listaTrabajosSolicitadosCitaTaller: action.payload.listaTrabajosSolicitadosCitaTaller,
        listaTrabajosRealizadosCitaTaller: action.payload.listaTrabajosRealizadosCitaTaller
      }
    case types.solicitarNuevoTrabajoCitaTaller:
      return {
        ...state,
        listaTrabajosSolicitadosCitaTaller: action.payload.listaTrabajosSolicitadosCitaTaller,
        listaTrabajosRealizadosCitaTaller: action.payload.listaTrabajosRealizadosCitaTaller
      }
    default:
      return state;
  }
}
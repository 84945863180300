import React, { useEffect } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { obtenerDatosCitaTaller, solicitarNuevoTrabajoCitaTaller } from '../redux/actions/citasTallerActions'
import { useDispatch, useSelector } from 'react-redux'
import uniqid from 'uniqid'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { CitaTallerNuevoTrabajo } from '../helpers/CitaTallerNuevoTrabajo'

export const CitaTallerVer = () => {
  const dispatch = useDispatch()

  const { citaTallerId } = useParams()
  const { citaTaller, listaTrabajosSolicitadosCitaTaller, listaTrabajosRealizadosCitaTaller } = useSelector(({ citasTallerReducer }) => citasTallerReducer)
  const { inConductorId } = useSelector(({ loginReducer }) => loginReducer)

  useEffect(() => {
    citaTallerId > 0 && dispatch(obtenerDatosCitaTaller(citaTallerId))
  }, [dispatch, citaTallerId])

  const nuevoTrabajo = () => {
    setTimeout(async () => {
      const trabajoSolicitado = await CitaTallerNuevoTrabajo()
      if (trabajoSolicitado !== '') {
        dispatch(solicitarNuevoTrabajoCitaTaller(citaTallerId, trabajoSolicitado, inConductorId))
      }
    }, 100)
  }

  return (
    <PageLayout>
      <div className='content d-flex flex-column'>
        <TitlePage title='CITA TALLER' />
        <div className='mb-3 px-3'>
          {
            (Object.keys(citaTaller).length > 0)
            &&
            <>
              <div className="row mb-3">
                <div className="col-sm-6">
                  <label htmlFor="indicativoServicio" className="form-label">Estado</label>
                  <span className='form-control form-control-sm'>
                    {citaTaller.ESTADO_CITA}
                  </span>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="indicativoConductor" className="form-label">Kilómetros</label>
                  <span className='form-control form-control-sm'>
                    {citaTaller.KMS_CITA}
                  </span>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-sm-6">
                  <label htmlFor="areaSanitaria" className="form-label">Fecha solicitud</label>
                  <span className='form-control form-control-sm'>
                    {citaTaller.FECHAHORA_SOLICITUD}
                  </span>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="areaSanitaria" className="form-label">Fecha cita</label>
                  <span className='form-control form-control-sm'>
                    {citaTaller.FECHAHORA_CITA === '' ? 'Pendiente de asignación' : citaTaller.FECHAHORA_CITA}
                  </span>
                </div>
              </div>


              <div className="row mb-3 mt-5">
                <div className="col">
                  <table className="table table-hover table-sm table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">
                          <div className="d-flex">
                            <div className="me-auto">
                              Trabajos solicitados
                            </div>
                            <div>
                              {
                                citaTaller.ESTADO_CITA === 'ABIERTA'
                                &&
                                <button className="btn btn-primary btn-sm" onClick={nuevoTrabajo}>NUEVO TRABAJO</button>
                              }
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    {
                      <tbody>
                        {
                          listaTrabajosSolicitadosCitaTaller.length > 0 &&
                          listaTrabajosSolicitadosCitaTaller.map((trabajoSolicitado) => {
                            return (
                              <tr key={uniqid()}>
                                <td className="text-break">
                                  {trabajoSolicitado.TRABAJO_SOLICITADO_DESCRIPCION}
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    }
                  </table>
                </div>
              </div>


              <div className="row mb-3 mt-5">
                <div className="col">
                  <table className="table table-hover table-sm table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">Trabajos realizados</th>
                      </tr>
                    </thead>
                    {
                      <tbody>
                        {
                          listaTrabajosRealizadosCitaTaller.length > 0 &&
                          listaTrabajosRealizadosCitaTaller.map((trabajoRealizado) => {
                            return (
                              <tr key={uniqid()}>
                                <td className="text-break">
                                  {trabajoRealizado.TRABAJO_REALIZADO_DESCRIPCION}
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    }
                  </table>
                </div>
              </div>
            </>
          }
        </div>
        <div className='text-end mt-auto pt-3 px-3'>
          <NavLink to='/citas-taller' className='btn btn-primary me-1'>VOLVER</NavLink>
        </div>
      </div>
    </PageLayout>
  )
}

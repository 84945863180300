import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { solicitudInicioComida, solicitudFinComida, resetearMensajeComida } from '../redux/actions/comidaActions'
import { Confirm } from '../helpers/Confirm'
import { Alert } from '../helpers/Alert'
import 'animate.css'

export const UserInfo = () => {
  const dispatch = useDispatch()
  const { indicativoServicioNombre, inIndicativoServicioId, inCuadranteId, conductorIndicativo, camilleroIndicativo, vehiculoMatricula } = useSelector(({ loginReducer }) => loginReducer);
  const { comiendo, mensajeRespuestaPeticion, mostrarMensaje, horaInicioComida } = useSelector(({ comidaReducer }) => comidaReducer)

  useEffect(() => {
    mostrarMensaje && Alert(mensajeRespuestaPeticion, 'ACEPTAR')
    dispatch(resetearMensajeComida())
  }, [mostrarMensaje, dispatch, mensajeRespuestaPeticion])

  const gestionComida = async () => {
    const confirm = await Confirm(`${comiendo ? 'FINALIZAR COMIDA. ' : 'INICIAR COMIDA. '}¿DESEA CONTINUAR?`, 'ACEPTAR', 'CANCELAR')
    if (confirm) {
      comiendo
        ? dispatch(solicitudFinComida(inIndicativoServicioId, inCuadranteId))
        : dispatch(solicitudInicioComida(inIndicativoServicioId, inCuadranteId))
    }
  }

  return (
    <div className='card animate__animated animate__fadeIn no-show' id='userinfo-card'>
      <ul className='list-group list-group-flush text-start'>
        <li className='list-group-item'>{`INDICATIVO DE SERVICIO:  ${indicativoServicioNombre}`}</li>
        <li className='list-group-item'>{`INDICATIVO DE CONDUCTOR: ${conductorIndicativo}`}</li>
        <li className='list-group-item'>{`INDICATIVO DE CAMILLERO: ${camilleroIndicativo}`}</li>
        <li className='list-group-item'>{`MATRICULA DE VEHICULO:   ${vehiculoMatricula}`}</li>
        {
          comiendo ?
            <>
              <li className='list-group-item'>{`INICIO COMIDA:   ${horaInicioComida}`}</li>
              <li className='list-group-item text-center py-3'><button onClick={gestionComida} className='btn btn-outline-light btn-sm'>FINALIZAR COMIDA</button></li>
            </>
            :
            <li className='list-group-item text-center py-3'><button onClick={gestionComida} className='btn btn-outline-light btn-sm'>INICIAR COMIDA</button></li>
        }
      </ul>
    </div>
  )
}

import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleSidebar } from '../redux/actions/sidebarActions'
import { FiX } from 'react-icons/fi';

export const SideBarCloseButton = () => {
  const dispatch = useDispatch()

  const closeSidebar = () => {
    document.getElementById('sidebar').classList.add('animate__slideOutLeft')
    setTimeout(() => {
      dispatch(toggleSidebar(false))
    }, 1000);
  }

  return (
    <>
      <FiX className='sidebar-close-button' onClick={closeSidebar} />
    </>
  )
}
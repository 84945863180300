import { types } from '../types'
import { fetchData } from '../fetchData'

// export const checkLogin = (values) => {
//   const proceso = 'A_COMPRUEBA_INICIO_JORNADA.PRO'
//   let params = `?IN_INDICATIVO_SERVICIO_TXT=${values.indicativoServicio}`
//   params = params + `&IN_INDICATIVO_CONDUCTOR_TXT=${values.indicativoConductor}`
//   params = params + `&IN_INDICATIVO_CAMILLERO_TXT=${values.indicativoCamillero}`
//   params = params + `&IN_VEHICULO_MATRICULA_TXT=${values.matriculaVehiculo}`
//   params = params + `&IN_VEHICULO_KMS_TXT=${values.kilometrosIniciales}`
//   params = params + `&IN_DISPOSITIVO_TXT=${values.dispositivoContacto}`
//   const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
//   const data = fetchData(url)
//   return ((dispatch) => { data.then(data => { data === 0 ? dispatch(loginError()) : dispatch(login(data)) }) })
// }

export const checkLogout = (values) => {
  const proceso = 'A_FIN_JORNADA.PRO'
  let params = `?IN_INDICATIVO_SERVICIO_ID=${values.inIndicativoServicioId}`
  params = params + `&IN_CUADRANTE_ID=${values.inCuadranteId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return ((dispatch) => { data.then(data => { data.FIN_JORNADA === 0 ? dispatch(logoutError(true)) : dispatch(logout()) }) })
}

export const compruebaCuadrante = () => {
  const sesnStorage = JSON.parse(sessionStorage.getItem('state'))
  const { dispositivo, inCuadranteId, camilleroIndicativo, conductorIndicativo, indicativoServicioNombre, inIndicativoServicioId, vehiculoMatricula } = sesnStorage.loginReducer
  const checkCuadrante = async () => {
    try {
      const proceso = 'A_COMPRUEBA_CUADRANTE.PRO'
      let params = `?IN_DISPOSITIVO=${dispositivo}`
      params = params + `&IN_INDICATIVO_CAMILLERO=${camilleroIndicativo}`
      params = params + `&IN_INDICATIVO_CONDUCTOR=${conductorIndicativo}`
      params = params + `&IN_INDICATIVO_SERVICIO=${indicativoServicioNombre}`
      params = params + `&IN_INDICATIVO_SERVICIO_ID=${inIndicativoServicioId}`
      params = params + `&IN_VEHICULO_MATRICULA=${vehiculoMatricula}`
      const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
      const data = fetchData(url, false)
      return data
    } catch (error) {
      console.warn(error)
    }
  }
  return (dispatch) => {
    checkCuadrante().then(data => {
      data.CUADRANTE_ID !== inCuadranteId
        ?
        console.log('Error Cuadrante')
        :
        console.log('Cuadrante correcto')
      data.CUADRANTE_ID !== inCuadranteId && dispatch(logout())
    })
  }
}

export const obtenerMensajeAvisos = (indicativoServicioNombre, inCuadranteId) => {
  const proceso = 'A_MENSAJE_AVISOS_ACTUALIZAR.pro'
  let params = `?IN_INDICATIVO_SERVICIO_TXT=${indicativoServicioNombre}`
  params = params + `&IN_CUADRANTE_ID=${inCuadranteId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return ((dispatch) => { data.then(data => { dispatch(actualizarMensajeAvisos(data.MENSAJE_AVISOS)) }) })
}


export const login = (data) => {
  return {
    type: types.login,
    payload: {
      isLoggedIn: true,
      servicioMedicoEnfermeria: data.SERVICIO_MEDICO_ENFERMERIA,
      camilleroIndicativo: data.CAMILLERO_INDICATIVO,
      conductorIndicativo: data.CONDUCTOR_INDICATIVO,
      esUrgencia: data.ES_URGENCIA,
      permitirGestionServiciosTelefono: data.PERMITIR_GESTION_SERVICIOS_EN_TLF === '1' ? true : false,
      mostrarColectivo: data.MOSTRAR_COLECTIVO,
      esServicioPreventivo: data.ES_SERVICIO_PREVENTIVO,
      indicativoServicioNombre: data.INDICATIVO_SERVICIO_NOMBRE,
      inCamilleroId: data.IN_CAMILLERO_ID,
      inConductorId: data.IN_CONDUCTOR_ID,
      inCuadranteId: data.IN_CUADRANTE_ID,
      inIndicativoServicioId: data.IN_INDICATIVO_SERVICIO_ID,
      inVehiculoId: data.IN_VEHICULO_ID,
      mensajeAvisos: data.MENSAJE_AVISOS,
      vehiculoMatricula: data.VEHICULO_MATRICULA,
      dispositivo: data.DISPOSITIVO_TXT,
      pedirFotosInicioJornada: data.PEDIR_FOTOS_INICIO_JORNADA === '1' ? true : false
    }
  }
}

// export const loginError = () => {
//   return {
//     type: types.loginError,
//     payload: {
//       isLoggedIn: false,
//       loginError: true
//     }
//   }
// }

export const logout = () => {
  return {
    type: types.logout,
    payload: {
      isLoggedIn: false
    }
  }
}

export const logoutError = (value) => {
  return {
    type: types.logoutError,
    payload: {
      logoutError: value
    }
  }
}

export const actualizarMensajeAvisos = (value) => {
  return {
    type: types.actualizarMensajeAvisos,
    payload: {
      mensajeAvisos: value
    }
  }
}

export const actualizarPedirFotosInicioJornada = (value) => {
  return {
    type: types.actualizarPedirFotosInicioJornada,
    payload: {
      pedirFotosInicioJornada: value
    }
  }
}
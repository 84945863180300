import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TitlePage } from '../components/TitlePage'
import { PageLayout } from '../components/PageLayout'
import { obtenerMensajeAvisos } from '../redux/actions/loginActions'
import uniqid from 'uniqid'
import { FotosInicioJornada } from '../components/FotosInicioJornada'
import logo from '../images/version_vertical.png'

export const Home = () => {
  const dispatch = useDispatch()
  const { mensajeAvisos, vehiculoMatricula, indicativoServicioNombre, inCuadranteId, pedirFotosInicioJornada, servicioMedicoEnfermeria } = useSelector(({ loginReducer }) => loginReducer)

  useEffect(() => {
    dispatch(obtenerMensajeAvisos(indicativoServicioNombre, inCuadranteId))
  }, [dispatch, inCuadranteId, indicativoServicioNombre])


  const compruebaVencimientoFecha = (fecha) => {
    if (fecha === '' || fecha === 'undefined') {
      return false
    } else {
      const dia = fecha.substr(0, 2)
      const mes = fecha.substr(3, 2)
      const anio = fecha.substr(6, 4)
      const dateA = new Date(`${mes}/${dia}/${anio}`)
      const dateB = new Date()
      if (dateA <= dateB) {
        return true
      } else {
        return false
      }
    }
  }

  const dangerVencimientoSeguro = 'FECHA_SEGURO' in mensajeAvisos ? compruebaVencimientoFecha(mensajeAvisos.FECHA_SEGURO) ? 'text-danger' : '' : ''
  const dangerVencimientoItv = 'FECHA_ITV' in mensajeAvisos ? compruebaVencimientoFecha(mensajeAvisos.FECHA_ITV) ? 'text-danger' : '' : ''
  const dangerVencimientoCitaItv = 'FECHA_HORA_CITA_ITV' in mensajeAvisos ? compruebaVencimientoFecha(mensajeAvisos.FECHA_HORA_CITA_ITV) ? 'text-danger' : '' : ''
  const dangerProximoMantenimiento = 'KM_PROXIMO_MANTENIMIENTO' in mensajeAvisos ? parseInt(mensajeAvisos.KM_PROXIMO_MANTENIMIENTO) <= parseInt(mensajeAvisos.KMS_INICIO) ? 'text-danger' : '' : ''
  const dangerVencimientoSanidad = 'FECHA_SANIDAD' in mensajeAvisos ? compruebaVencimientoFecha(mensajeAvisos.FECHA_SANIDAD) ? 'text-danger' : '' : ''

  return (
    <PageLayout>
      <div className='content d-flex flex-column' >
        <TitlePage title={`BIENVENIDO ${indicativoServicioNombre}`} />
        {
          servicioMedicoEnfermeria
            ?
            <div className='d-flex p-5 justify-content-center align-items-center'>
              <img src={logo} className='img-fluid' alt='Logo home' />
            </div>
            :
            <div className='card mt-3 card-container'>
              <div className='card-header'>
                <div className='d-flex fw-bold'>
                  <div className='me-auto'>{vehiculoMatricula}</div>
                  <div>{`${mensajeAvisos.KMS_INICIO} Km.`}</div>
                </div>
              </div>
              <div className='card-body py-4'>
                {
                  pedirFotosInicioJornada
                    ?
                    <FotosInicioJornada />
                    :
                    <>
                      <p className='text-center pt-1 pb-1 fs-6'>{mensajeAvisos.MENSAJE_FECHAS_VEHICULO}</p>
                      <hr />
                      <table className='table table-borderless table-sm mt-4'>
                        <thead>
                          <tr>
                            <th className='colspan2'>SEGURO</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Fecha vencimiento seguro:</td>
                            <td className={`text-end ${dangerVencimientoSeguro}`}>{mensajeAvisos.FECHA_SEGURO === '' ? 'No disponible' : mensajeAvisos.FECHA_SEGURO}</td>
                          </tr>
                        </tbody>
                      </table>
                      <hr />
                      <table className='table table-borderless table-sm'>
                        <thead>
                          <tr>
                            <th className='colspan2'>ITV</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Fecha vencimiento ITV:</td>
                            <td className={`text-end ${dangerVencimientoItv}`}>{mensajeAvisos.FECHA_ITV}</td>
                          </tr>
                          <tr>
                            <td>Fecha y hora cita ITV:</td>
                            <td className={`text-end ${dangerVencimientoCitaItv}`}>{mensajeAvisos.FECHA_HORA_CITA_ITV === '' ? 'No disponible' : mensajeAvisos.FECHA_HORA_CITA_ITV}</td>
                          </tr>
                        </tbody>
                      </table>
                      <hr />
                      <table className='table table-borderless table-sm mt-4'>
                        <thead>
                          <tr>
                            <th className='colspan2'>MANTENIMIENTOS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Kilómetros próximo mantenimiento:</td>
                            <td className={`text-end ${dangerProximoMantenimiento}`}>{mensajeAvisos.KM_PROXIMO_MANTENIMIENTO === '' ? 'No disponible' : `${mensajeAvisos.KM_PROXIMO_MANTENIMIENTO} Km`}</td>
                          </tr>
                        </tbody>
                      </table>
                      <hr />
                      <table className='table table-borderless table-sm mt-4'>
                        <thead>
                          <tr>
                            <th className='colspan2'>SANIDAD</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Fecha vencimiento sanidad:</td>
                            <td className={`text-end ${dangerVencimientoSanidad}`}>{mensajeAvisos.FECHA_SANIDAD}</td>
                          </tr>
                        </tbody>
                      </table>
                      <hr />
                      <table className='table table-borderless table-sm mt-4'>
                        <thead>
                          <tr>
                            <th>CALIDAD</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Fecha último registro de calidad:</td>
                            <td className='text-end'>{mensajeAvisos.FECHA_ULTIMO_REGISTRO_CALIDAD === '' ? 'No disponible' : mensajeAvisos.FECHA_ULTIMO_REGISTRO_CALIDAD}</td>
                          </tr>

                        </tbody>
                      </table>
                      {
                        'LISTA_MATERIAL_CADUCIDAD' in mensajeAvisos
                        &&
                        <>
                          <hr />
                          <table className='table mt-4'>
                            <thead>
                              <tr>
                                <th colSpan='3'>AVISOS MATERIAL</th>
                              </tr>
                              <tr>
                                <th>Material</th>
                                <th>Stock</th>
                                <th>Vencimiento</th>
                              </tr>
                            </thead>
                            <tbody>
                              {mensajeAvisos.LISTA_MATERIAL_CADUCIDAD.map(lista => {
                                return (
                                  <tr key={uniqid()}>
                                    <td>{lista.MATERIAL.toLowerCase()}</td>
                                    <td className='text-center'>{lista.MATERIAL_CANTIDAD.toLowerCase()}</td>
                                    <td className='text-center'>{lista.MATERIAL_FECHA_CADUCIDAD.toLowerCase()}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </>
                      }
                    </>
                }
              </div>
            </div>
        }
        {/* <div className='mt-auto'>BOTONES</div> */}
      </div>
    </PageLayout>
  )
}
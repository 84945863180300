import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { nuevoRepostaje, obtenerDatosSolicitudNuevoRepostaje, enviarDatosNuevoRepostaje } from '../redux/actions/repostajesActions'
import { Confirm } from '../helpers/Confirm'
import { fileToBase64 } from '../helpers/fileToBase64'
import { formatFileSize } from '../helpers/formatFileSize'

export const RepostajeNuevo = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [imagenTicket, setImagenTicket] = useState('')

  const {
    listaAreas,
    listaGasolineras,
    listaTiposCombustible,
    respuestaNuevoRepostaje,
    mensajeNuevoRepostaje
  } = useSelector(({ repostajesReducer }) => repostajesReducer)

  const {
    inConductorId,
    conductorIndicativo,
    inVehiculoId,
    vehiculoMatricula,
    inIndicativoServicioId,
    indicativoServicioNombre
  } = useSelector(({ loginReducer }) => loginReducer)

  useEffect(() => {
    dispatch(obtenerDatosSolicitudNuevoRepostaje())
    return () => {
      dispatch(nuevoRepostaje({ 'ESTADO_NUEVO_REPOSTAJE': '', 'MENSAJE_ERROR': '' }))
    }
  }, [dispatch])

  useEffect(() => {
    respuestaNuevoRepostaje === '1'
      ?
      setTimeout(async () => {
        const resp = await Confirm('REPOSTAJE CREADO CORRECTAMENTE', 'ACEPTAR')
        if (resp) {
          navigate('/repostajes')
        }
      }, 100)
      :
      respuestaNuevoRepostaje !== ''
      &&
      document.querySelector('#alert').classList.remove('no-show')

  }, [respuestaNuevoRepostaje, mensajeNuevoRepostaje, navigate])


  const formik = useFormik({
    initialValues: {
      conductorId: inConductorId,
      indicativoConductor: conductorIndicativo,
      indicativoServicioId: inIndicativoServicioId,
      indicativoServicio: indicativoServicioNombre,
      vehiculoId: inVehiculoId,
      vehiculoMatricula: vehiculoMatricula,
      areaSanitaria: '',
      gasolinera: '',
      tipoCombustible: '',
      codigoTarjeta: '',
      codigoTicket: '',
      fechaRepostaje: moment().format('YYYY-MM-DD'),
      importe: '',
      litros: '',
      vehiculoKms: '',
      base64File: ''
    },
    validationSchema: Yup.object(
      {
        areaSanitaria: Yup.string().required('Campo requerido.'),
        gasolinera: Yup.string().required('Campo requerido.'),
        tipoCombustible: Yup.string().required('Campo requerido.'),
        codigoTarjeta: Yup.string().required('Campo requerido.'),
        codigoTicket: Yup.string().required('Campo requerido.'),
        fechaRepostaje: Yup.date().required('Campo requerido').max(moment().format('YYYY-MM-DD'), 'Fecha incorrecta.'),
        importe: Yup.string().required('Campo requerido').matches(/^[0-9]+(,[0-9]+)?$/, 'Formato incorrecto.'),
        litros: Yup.string().required('Campo requerido').matches(/^[0-9]+(,[0-9]+)?$/, 'Formato incorrecto.'),
        vehiculoKms: Yup.string().required('Campo requerido').matches(/^[0-9]+([0-9]+)?$/, 'Formato incorrecto.'),
        vehiculoMatricula: Yup.string().required('Campo requerido').matches(/^[0-9]{4}-[A-Z|a-z]{3}$/, 'Formato incorrecto')
      }
    ),
    onSubmit: values => {
      setTimeout(async () => {
        const resp = await Confirm('SE VA A CREAR UN NUEVO REPOSTAJE. ¿DESEA CONTINUAR?', 'ACEPTAR', 'CANCELAR')
        resp
          &&
          dispatch(enviarDatosNuevoRepostaje(values))
      }, 100)
    },
  })

  useEffect(() => {
    if (formik.values.areaSanitaria === '') {
      formik.values.gasolinera = ''
    }
  }, [formik.values])

  const cambiarMatricula = (e) => {
    const element = document.getElementById(e.target.id)
    if (element.checked) {
      document.getElementById('vehiculoMatricula').removeAttribute('disabled')
    } else {
      document.getElementById('vehiculoMatricula').setAttribute('disabled', '')
    }
  }

  const getImageFromCamera = async (e) => {
    const image = e.target.files[0]
    const imageUrl = URL.createObjectURL(image)
    const fileSize = formatFileSize(image.size)
    console.log(fileSize)
    setImagenTicket(imageUrl)
    const compressedImageInBase64 = await fileToBase64(image)
    formik.values.base64File = compressedImageInBase64.split(',')[1]
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    formik.setFieldValue(name, value.toUpperCase())
  }

  return (
    <PageLayout>
      <div className='content d-flex flex-column'>
        <TitlePage title='NUEVO REPOSTAJE' />
        {/* <div className='text-end mb-3'>
          <NavLink to='/repostajes' className='btn btn-primary me-1'>CANCELAR</NavLink>
          <button type='submit' form='nuevoRepostaje' className='btn btn-primary'>ACEPTAR</button>
        </div> */}
        <div className='mb-3 px-3'>
          <form onSubmit={formik.handleSubmit} id='nuevoRepostaje'>
            <input type='hidden' name='conductorId' id='conductorId' value={formik.values.conductorId} />
            <input type='hidden' name='indicativoServicioId' id='indicativoServicioId' value={formik.values.indicativoServicioId} />
            <input type='hidden' name='vehiculoId' id='vehiculoId' value={formik.values.vehiculoId} />
            <input type='hidden' name='base64File' id='base64File' value={formik.values.base64File} />

            <div className='row mb-3'>
              <div className='col'>
                <div className='form-check'>
                  <input className='form-check-input' type='checkbox' id='cambiarMatricula' onChange={cambiarMatricula} />
                  <label className='form-check-label' htmlFor='cambiarMatricula'>
                    Modificar matrícula
                  </label>
                </div>
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-sm-4'>
                <label htmlFor='indicativoServicio' className='form-label'>Indicativo servicio</label>
                <input
                  className='form-control form-control-sm'
                  type='text'
                  name='indicativoServicio'
                  id='indicativoServicio'
                  value={formik.values.indicativoServicio}
                  disabled
                />
              </div>
              <div className='col-sm-4'>
                <label htmlFor='indicativoConductor' className='form-label'>Indicativo conductor</label>
                <input
                  className='form-control form-control-sm'
                  type='text'
                  name='indicativoConductor'
                  id='indicativoConductor'
                  value={formik.values.indicativoConductor}
                  disabled
                />
              </div>
              <div className='col-sm-4'>
                <label htmlFor='vehiculoMatricula' className='form-label'>Matrícula</label>
                <input
                  className={`form-control form-control-sm ${(formik.touched.vehiculoMatricula && formik.errors.vehiculoMatricula) && 'form-control-error'}`}
                  type='text'
                  name='vehiculoMatricula'
                  id='vehiculoMatricula'
                  value={formik.values.vehiculoMatricula}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  disabled
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col'>
                <label htmlFor='areaSanitaria' className='form-label'>Área sanitaria</label>
                <select
                  className={`form-select ${(formik.touched.areaSanitaria && formik.errors.areaSanitaria) && 'form-control-error'}`}
                  id='areaSanitaria'
                  name='areaSanitaria'
                  value={formik.values.areaSanitaria}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value=''>Seleccione un área sanitaria...</option>
                  {
                    listaAreas.map(areaSanitaria => {
                      return (
                        <option key={areaSanitaria.IN_AREA_ID} value={areaSanitaria.IN_AREA_ID}>{areaSanitaria.NOMBRE_AREA}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col'>
                <label htmlFor='gasolinera' className='form-label'>Gasolinera</label>
                <select
                  className={`form-select ${(formik.touched.gasolinera && formik.errors.gasolinera) && 'form-control-error'}`}
                  id='gasolinera'
                  name='gasolinera'
                  value={formik.values.gasolinera}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value=''>Seleccione una gasolinera...</option>
                  {
                    listaGasolineras.filter(gasolinera => parseInt(gasolinera.IN_AREA_ID) === parseInt(formik.values.areaSanitaria)).map(gasolinera => {
                      return (
                        <option key={gasolinera.IN_GASOLINERA_ID} value={gasolinera.IN_GASOLINERA_ID}>{gasolinera.NOMBRE_GASOLINERA}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col'>
                <label htmlFor='tipoCombustible' className='form-label'>Tipo de combustible</label>
                <select
                  className={`form-select ${(formik.touched.tipoCombustible && formik.errors.tipoCombustible) && 'form-control-error'}`}
                  id='tipoCombustible'
                  name='tipoCombustible'
                  value={formik.values.tipoCombustible}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value=''>Seleccione tipo de combustible...</option>
                  {
                    listaTiposCombustible.map(tipoCombustible => {
                      return (
                        <option key={tipoCombustible.IN_TIPO_COMBUSTIBLE_ID} value={tipoCombustible.IN_TIPO_COMBUSTIBLE_ID}>{tipoCombustible.NOMBRE_TIPO_COMBUSTIBLE}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-sm-6'>
                <div className='mb-1'>
                  <label htmlFor='codigoTarjeta' className='form-label'>Código de tarjeta</label>
                  <input
                    type='text'
                    className={`form-control form-control-sm ${(formik.touched.codigoTarjeta && formik.errors.codigoTarjeta) && 'form-control-error'}`}
                    id='codigoTarjeta'
                    name='codigoTarjeta'
                    value={formik.values.codigoTarjeta}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={`Ej: 1234-ABC o 1234ABC`}
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='mb-1'>
                  <label htmlFor='codigoTicket' className='form-label'>Código del ticket</label>
                  <input
                    type='text'
                    className={`form-control form-control-sm ${(formik.touched.codigoTicket && formik.errors.codigoTicket) && 'form-control-error'}`}
                    id='codigoTicket'
                    name='codigoTicket'
                    value={formik.values.codigoTicket}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-sm-3'>
                <label htmlFor='fechaRepostaje' className='form-label'>Fecha repostaje</label>
                <input
                  type='date'
                  className={`form-control form-control-sm ${(formik.touched.fechaRepostaje && formik.errors.fechaRepostaje) && 'form-control-error'}`}
                  id='fechaRepostaje'
                  name='fechaRepostaje'
                  value={formik.values.fechaRepostaje}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className='col-sm-3'>
                <label htmlFor='importe' className='form-label'>Importe</label>
                <input
                  type='text'
                  className={`form-control form-control-sm ${(formik.touched.importe && formik.errors.importe) && 'form-control-error'}`}
                  id='importe'
                  name='importe'
                  // placeholder='Ej: 12,34'
                  value={formik.values.importe}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className='col-sm-3'>
                <label htmlFor='litros' className='form-label'>Litros</label>
                <input
                  type='text'
                  className={`form-control form-control-sm ${(formik.touched.litros && formik.errors.litros) && 'form-control-error'}`}
                  id='litros'
                  name='litros'
                  // placeholder='Ej: 12,34'
                  value={formik.values.litros}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className='col-sm-3'>
                <label htmlFor='vehiculoKms' className='form-label'>Kilómetros</label>
                <input
                  type='text'
                  className={`form-control form-control-sm ${(formik.touched.vehiculoKms && formik.errors.vehiculoKms) && 'form-control-error'}`}
                  id='vehiculoKms'
                  name='vehiculoKms'
                  // placeholder='Ej: 45456'
                  value={formik.values.vehiculoKms}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col'>
                <label htmlFor='imagenTicket' className='form-label'>{`Adjuntar ticket (Haga clic en el botón "Seleccionar archivo" para abrir la cámara)`}</label>
                <input
                  type='file'
                  className={`form-control form-control-sm`}
                  id='imagenTicket'
                  name='imagenTicket'
                  capture='camera'
                  accept='image/jpeg'
                  onChange={getImageFromCamera}
                />
              </div>
            </div>

            {
              imagenTicket !== ''
              &&
              <div className='row mb-3'>
                <div className='col text-center'>
                  <img src={imagenTicket} width='300' className='img-fluid' alt='Ticket repostaje'></img>
                </div>
              </div>
            }
          </form >
        </div>
        <div className='text-end mt-auto pt-3 px-3'>
          <div className='alert alert-primary text-center no-show' id='alert' role='alert'>
            {
              mensajeNuevoRepostaje.toUpperCase()
            }
          </div>
          <NavLink to='/repostajes' form='nuevoRepostaje' className='btn btn-primary me-1'>CANCELAR</NavLink>
          <button type='submit' form='nuevoRepostaje' className='btn btn-primary'>ACEPTAR</button>
        </div>
      </div>
    </PageLayout>
  )
}
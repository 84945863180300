import React from 'react'
import ReactDOM from 'react-dom/client'
import { AlertModal } from '../components/AlertModal'

export const Alert = (msg, acceptBtn = 'OK') => {
  return (
    showModal(msg, acceptBtn)
  )
}

const showModal = (msg, acceptBtn) => {
  const body = document.getElementsByTagName('body')[0]
  const div = document.createElement('div')
  div.setAttribute('id', 'modal-container')
  body.appendChild(div)
  const modalContainer = ReactDOM.createRoot(div)
  modalContainer.render(<AlertModal msg={msg} acceptBtn={acceptBtn} />)
}

import { types } from '../types';

const initialValues = {
  listaAtenciones: [],
  codigosActuacion: []
}

export const registroAtencionesReducer = (state = initialValues, action) => {
  switch (action.type) {
    case types.listarCodigosActuacion:
      return {
        ...state,
        codigosActuacion: action.payload.codigosActuacion
      }
    case types.listarAtenciones:
      return {
        ...state,
        listaAtenciones: action.payload.listaAtenciones
      }
    default:
      return state;
  }
}
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ChangeLogModal } from '../components/ChangeLogModal'

export const ChangeLog = () => {
  const body = document.getElementsByTagName('body')[0]
  const div = document.createElement('div')
  div.setAttribute('id', 'modal-container')
  body.appendChild(div)
  const modalContainer = ReactDOM.createRoot(div)
  modalContainer.render(<ChangeLogModal />)

}
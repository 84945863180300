import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../redux/store'
import { ServicioIncidenciasForm } from '../components/ServicioIncidenciasModalForm'

export const ServicioIncidenciasModal = ({ resolve, reject, servicio }) => {
  return (
    <Provider store={store}>
      <ServicioIncidenciasForm
        resolve={resolve}
        reject={reject}
        servicio={servicio}
      />
    </Provider>
  )
}
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { solicitarListaCodigosActuacion, nuevoRegistroDeAtencion, modificarRegistroDeAtencion } from '../redux/actions/registroAtencionesActions'
import { Confirm } from '../helpers/Confirm'
import { AlertaServiciosDesdeMovil } from '../components/AlertaServiciosDesdeMovil'

export const ServicioGenericoRegistroAtencion = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { servicioId, servicioAnio, servicioTabla, atencionId } = useParams()
  const { listaAtenciones, codigosActuacion } = useSelector(({ registroAtencionesReducer }) => registroAtencionesReducer)
  const { permitirGestionServiciosTelefono } = useSelector(({ loginReducer }) => loginReducer)

  const goback = () => {
    navigate(-1)
  }

  const orientation = window.screen.orientation.type
  const widthScreen = window.screen.width
  const heightScreen = window.screen.height

  let esPantallaTelefono = false

  if ((orientation === 'landscape-primary' && widthScreen < Number(750) && heightScreen < Number(370)) || (orientation === 'portrait-primary' && widthScreen < Number(370) && heightScreen < Number(750))) {
    esPantallaTelefono = true
  }

  const [initialValues, setInitialValues] = useState(
    {
      servicioId: servicioId,
      servicioAnio: servicioAnio,
      servicioTabla: servicioTabla,
      atencionId: 0,
      fecha: moment().format("YYYY-MM-DD"),
      hora: moment().format("HH:mm"),
      nombreApellidos: '',
      dni: '',
      dorsal: 0,
      edad: 0,
      traslado: false,
      licencia: false,
      lugar: '',
      sexo: '',
      informeMedicoNro: '',
      codigoActuacion: '',
      intervinientes: '',
      observaciones: ''
    }
  )

  useEffect(() => {
    dispatch(solicitarListaCodigosActuacion())
    if (atencionId !== undefined) {
      const atencion = listaAtenciones.filter(atencion => {
        return (atencion.IN_ATENCION_ID.toString() === atencionId)
      })
      const atencionModificar = atencion[0]

      setInitialValues(
        {
          servicioId: servicioId,
          servicioAnio: servicioAnio,
          servicioTabla: servicioTabla,
          atencionId: atencionModificar.IN_ATENCION_ID,
          fecha: `${atencionModificar.ATENCION_FECHA.substr(6)}-${atencionModificar.ATENCION_FECHA.substr(3, 2)}-${atencionModificar.ATENCION_FECHA.substr(0, 2)}`,
          hora: atencionModificar.ATENCION_HORA,
          nombreApellidos: atencionModificar.ATENCION_NOMBRE_APELLIDOS,
          dni: atencionModificar.ATENCION_DNI,
          dorsal: atencionModificar.ATENCION_DORSAL,
          edad: atencionModificar.ATENCION_EDAD,
          traslado: atencionModificar.ATENCION_TRASLADO === '1' ? true : false,
          licencia: atencionModificar.ATENCION_LICENCIA === '1' ? true : false,
          lugar: atencionModificar.ATENCION_PROCEDENCIA,
          sexo: atencionModificar.ATENCION_SEXO === 'MUJER' ? 'M' : 'V',
          informeMedicoNro: atencionModificar.ATENCION_NUMERO_INFORME_MEDICO,
          codigoActuacion: atencionModificar.ATENCION_CODIGO_ATENCION,
          intervinientes: atencionModificar.ATENCION_INTERVINIENTES,
          observaciones: atencionModificar.ATENCION_OBSERVACIONES
        }
      )
    }
  }, [dispatch, listaAtenciones, servicioId, servicioAnio, servicioTabla, atencionId])

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object(
      {
        hora: Yup.string().required('Campo requerido'),
        nombreApellidos: Yup.string().required('Campo requerido'),
        dni: Yup.string().required('Campo requerido'),
        dorsal: Yup.number().required('Campo requerido'),
        edad: Yup.number().required('Campo requerido'),
        traslado: Yup.string().required('Campo requerido'),
        licencia: Yup.string().required('Campo requerido'),
        lugar: Yup.string().required('Campo requerido'),
        sexo: Yup.string().required('Campo requerido'),
        informeMedicoNro: Yup.string().required('Campo requerido'),
        codigoActuacion: Yup.string().required('Campo requerido'),
        intervinientes: Yup.string().required('Campo requerido')
      }
    ),
    onSubmit: values => {
      console.log(values)
      setTimeout(async () => {
        const resp = await Confirm(`${atencionId === undefined ? 'SE VA A CREAR UN NUEVO REGISTRO DE ATENCIÓN.' : ' SE VA A MODIFICAR EL REGISTRO DE ATENCIÓN.'} ¿DESEA CONTINUAR?`, 'ACEPTAR', 'CANCELAR')
        if (resp) {
          if (atencionId === undefined) {
            dispatch(nuevoRegistroDeAtencion(values))
          } else {
            dispatch(modificarRegistroDeAtencion(values))
          }
        }
        navigate(`/servicio-generico-registros-atenciones/${servicioId}/${servicioAnio}/${servicioTabla}`)
      }, 100)
    },
  })

  return (
    <PageLayout>
      <div className='content d-flex flex-column'>
        <TitlePage title={`${atencionId === undefined ? 'NUEVO' : 'MODIFICAR'} REGISTRO DE ATENCIÓN`} />
        {
          !esPantallaTelefono || (esPantallaTelefono && permitirGestionServiciosTelefono)
            ?
            <>
              <div className='mb-3'>

                <form onSubmit={formik.handleSubmit} id='nuevoRegistroAtencion'>
                  <input type='hidden' name='servicioId' id='servicioId' value={formik.values.servicioId} />
                  <input type='hidden' name='servicioAnio' id='servicioAnio' value={formik.values.servicioAnio} />
                  <input type='hidden' name='servicioTabla' id='servicioTabla' value={formik.values.servicioTabla} />
                  {
                    atencionId !== undefined &&
                    <input type='hidden' name='atencionId' id='atencionId' value={formik.values.atencionId} />
                  }

                  <div className='row mb-3'>
                    <div className='col-sm-3'>
                      <label htmlFor='fecha' className='form-label'>Fecha</label>
                      <input
                        className='form-control form-control-sm'
                        type='date'
                        name='fecha'
                        id='fecha'
                        value={formik.values.fecha}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className='col-sm-2'>
                      <label htmlFor='hora' className='form-label'>Hora</label>
                      <input
                        className='form-control form-control-sm'
                        type='time'
                        name='hora'
                        id='hora'
                        value={formik.values.hora}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className='row mb-5'>
                    <div className='col'>
                      <label htmlFor='codigoActuacion' className='form-label'>Código de actuación</label>
                      <select
                        className={`form-select form-select-sm ${(formik.touched.codigoActuacion && formik.errors.codigoActuacion) && 'form-control-error'}`}
                        id='codigoActuacion'
                        name='codigoActuacion'
                        value={formik.values.codigoActuacion}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value=''>Seleccione un código...</option>
                        {
                          codigosActuacion.map((codigo, index) => {
                            return (
                              <option key={index} value={codigo.IN_CODIGO_ACTUACION_ID}>{`${codigo.IN_CODIGO_ACTUACION_ID} - ${codigo.NOMBRE_CODIGO_ACTUACION}`}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col'>
                      <label htmlFor='nombreApellidos' className='form-label'>Nombre y apellidos</label>
                      <input
                        className={`form-control form-control-sm ${(formik.touched.nombreApellidos && formik.errors.nombreApellidos) && 'form-control-error'}`}
                        type='text'
                        name='nombreApellidos'
                        id='nombreApellidos'
                        value={formik.values.nombreApellidos}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className='row mb-5'>
                    <div className='col-sm-3'>
                      <label htmlFor='dni' className='form-label'>DNI</label>
                      <input
                        className={`form-control form-control-sm ${(formik.touched.dni && formik.errors.dni) && 'form-control-error'}`}
                        type='text'
                        name='dni'
                        id='dni'
                        value={formik.values.dni}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className='col-sm-2'>
                      <label htmlFor='edad' className='form-label'>Edad</label>
                      <input
                        className={`form-control form-control-sm ${(formik.touched.edad && formik.errors.edad) && 'form-control-error'}`}
                        type='number'
                        name='edad'
                        id='edad'
                        value={formik.values.edad}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className='col-sm-2'>
                      <label htmlFor='dorsal' className='form-label'>Dorsal</label>
                      <input
                        className={`form-control form-control-sm ${(formik.touched.dorsal && formik.errors.dorsal) && 'form-control-error'}`}
                        type='number'
                        name='dorsal'
                        id='dorsal'
                        value={formik.values.dorsal}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className='col-sm-3'>
                      <label htmlFor='gasolinera' className='form-label'>Sexo</label>
                      <select
                        className={`form-select form-select-sm ${(formik.touched.sexo && formik.errors.sexo) && 'form-control-error'}`}
                        id='sexo'
                        name='sexo'
                        value={formik.values.sexo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value=''>Selecione el sexo...</option>
                        <option value='V'>Varón</option>
                        <option value='M'>Mujer</option>
                      </select>
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col'>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='traslado'
                          checked={formik.values.traslado}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <label className='form-check-label' htmlFor='traslado'>
                          Traslado
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col'>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='licencia'
                          checked={formik.values.licencia}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <label className='form-check-label' htmlFor='licencia'>
                          Licencia
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col'>
                      <label htmlFor='lugar' className='form-label'>Lugar de origen</label>
                      <input
                        className={`form-control form-control-sm ${(formik.touched.lugar && formik.errors.lugar) && 'form-control-error'}`}
                        type='text'
                        name='lugar'
                        id='lugar'
                        value={formik.values.lugar}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-sm-3'>
                      <label htmlFor='informeMedicoNro' className='form-label'>Nº de informe médico</label>
                      <input
                        className={`form-control form-control-sm ${(formik.touched.informeMedicoNro && formik.errors.informeMedicoNro) && 'form-control-error'}`}
                        type='text'
                        name='informeMedicoNro'
                        id='informeMedicoNro'
                        value={formik.values.informeMedicoNro}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className='col-sm-9'>
                      <label htmlFor='intervinientes' className='form-label'>Intervinientes separados por /</label>
                      <input
                        className={`form-control form-control-sm ${(formik.touched.intervinientes && formik.errors.intervinientes) && 'form-control-error'}`}
                        type='text'
                        name='intervinientes'
                        id='intervinientes'
                        value={formik.values.intervinientes}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col'>
                      <label htmlFor="descripcion" className='form-label'>Observaciones</label>
                      <textarea
                        name="observaciones"
                        id="observaciones"
                        cols="30"
                        rows="10"
                        value={formik.values.observaciones}
                        className={`form-control form-control-sm ${(formik.touched.observaciones && formik.errors.observaciones) && 'form-control-error'}`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                      </textarea>
                    </div>
                  </div>

                </form >
              </div>

              <div className='text-end mt-auto pt-3'>
                <button type='button' onClick={goback} className='btn btn-primary me-1'>CANCELAR</button>
                <button type='submit' form='nuevoRegistroAtencion' className='btn btn-primary'>ACEPTAR</button>
              </div>
            </>
            :
            <AlertaServiciosDesdeMovil />
        }
      </div>
    </PageLayout>
  )
}
import { types } from '../types'
import { fetchData } from '../fetchData'

export const obtenerListaServicios = (inIndicativoServicioId) => {
  const proceso = 'A_VER_SERVICIOS.PRO'
  let params = `?IN_INDICATIVO_SERVICIO_ID=${inIndicativoServicioId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => {
    data.then(data => {
      const filteredData = data.LISTA_SERVICIOS.filter(item => { return (item.SERVICIO_ESTADO === 'ANULADO' || item.SERVICIO_ESTADO === 'FINALIZADO') || item.SERVICIO_ESTADO === 'PENDIENTE FINALIZACION' })
      dispatch(verListaServicios(filteredData))
    })
  }
}

export const obtenerServicio = (idServicio, tabla, anio, inIndicativoServicioId, inCuadranteId) => {
  const proceso = 'A_VER_SERVICIO.PRO'
  let params = `?IN_SERVICIO_ID=${idServicio}`
  params = params + `&IN_TABLA=${tabla}`
  params = params + `&IN_ANIO=${anio}`
  params = params + `&IN_INDICATIVO_SERVICIO_ID=${inIndicativoServicioId}`
  params = params + `&IN_CUADRANTE_ID=${inCuadranteId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => { data.then(data => { dispatch(verServicio(data)) }) }
}

export const obtenerServiciosActuales = (inIndicativoServicioId, inCuadranteId, altaServicio = 0) => {
  const proceso = 'A_VER_SERVICIO_ACTUAL.PRO'
  let params = `?IN_INDICATIVO_SERVICIO_ID=${inIndicativoServicioId}`
  params = params + `&IN_CUADRANTE_ID=${inCuadranteId}`
  params = params + `&IN_ALTA_SERVICIO_FLAG=${altaServicio}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  let ultimoServicioActualModificado = 0
  'IN_SERVICIO_ID' in data
    ?
    ultimoServicioActualModificado = data.IN_SERVICIO_ID
    :
    ultimoServicioActualModificado = 0
  return (dispatch) => { data.then(data => { dispatch(verServiciosActuales(data.LISTA_SERVICIOS, ultimoServicioActualModificado)) }) }
}

export const obtenerServiciosCentroDeDia = (inCuadranteId, inIndicativoServicioId, inCentroDeDiaId) => {
  const proceso = 'A_CREA_SERVICIO_CENTRO_DIA'
  let params = `?IN_CUADRANTE_ID=${inCuadranteId}`
  params = params + `&IN_INDICATIVO_SERVICIO_ID=${inIndicativoServicioId}`
  params = params + `&IN_CENTRO_DIA_ID=${inCentroDeDiaId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => { data.then(dispatch(obtenerServiciosActuales(inIndicativoServicioId, inCuadranteId, data.IN_SERVICIO_ID))) }
}

export const obtenerServiciosGenericos = (inCuadranteId, inIndicativoServicioId) => {
  const proceso = 'A_VER_SERVICIO_GENERICO_ACTUAL.PRO'
  let params = `?IN_CUADRANTE_ID=${inCuadranteId}`
  params = params + `&IN_INDICATIVO_SERVICIO_ID=${inIndicativoServicioId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => { data.then(data => { dispatch(verServiciosGenericosActuales(data.LISTA_SERVICIOS_GENERICOS)) }) }
}

export const marcarServicio = (idServicio, codigoModificacion, anio, codigoFinalizacion = 0, kmsRecorridos = 0, pacientesConSilla = 0, pacientesSinSilla = 0) => {
  const proceso = 'A_MARCAR_SERVICIO.PRO'
  let params = `?IN_SERVICIO_ID=${idServicio}`
  params = params + `&IN_ESTADO_NUEVO=${codigoModificacion}`
  params = params + `&IN_ANIO=${anio}`
  params = params + `&IN_TIPO_FINALIZACION=${codigoFinalizacion}`
  params = params + `&IN_KMS_RECORRIDOS=${kmsRecorridos}`
  params = params + `&IN_PACIENTESCONSILLA=${pacientesConSilla}`
  params = params + `&IN_PACIENTESSINSILLA=${pacientesSinSilla}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  let ultimoServicioActualModificado = 0
  'IN_SERVICIO_ID' in data
    ?
    ultimoServicioActualModificado = data.IN_SERVICIO_ID
    :
    ultimoServicioActualModificado = 0
  return (dispatch) => { data.then(data => { dispatch(verServiciosActuales(data.LISTA_SERVICIOS, ultimoServicioActualModificado)) }) }
}

export const marcarServicioGenerico = (inCuadranteId, inIndicativoServicioId, idServicio, codigoModificacion, anio, codigoFinalizacion = 0, kmsRecorridos = 0, pacientesConSilla = 0, pacientesSinSilla = 0) => {
  const proceso = 'A_MARCAR_SERVICIO.PRO'
  let params = `?IN_ANIO=${anio}`
  params = params + `&IN_ESTADO_NUEVO=${codigoModificacion}`
  params = params + `&IN_SERVICIO_ID=${idServicio}`
  params = params + `&IN_TIPO_FINALIZACION=${codigoFinalizacion}`
  params = params + `&IN_KMS_RECORRIDOS=${kmsRecorridos}`
  params = params + `&IN_PACIENTESCONSILLA=${pacientesConSilla}`
  params = params + `&IN_PACIENTESSINSILLA=${pacientesSinSilla}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => { data.then(data => { dispatch(obtenerServiciosGenericos(inCuadranteId, inIndicativoServicioId)) }) }
}

export const marcarServicioUrgenciaComoSecundario = (tabla, anio, servicioId) => {
  const proceso = 'A_MARCAR_SERVICIO_COMO_SECUNDARIO.PRO'
  let params = `?IN_TABLA=${tabla}`
  params = params + `&IN_ANIO=${anio}`
  params = params + `&IN_SERVICIO_ID=${servicioId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch => { data.then(data => { dispatch(verServicio(data)) }) })
}

export const informacionServicio = ({ anio, nss, dni, observaciones, origen, destino, paciente, idServicio, tabla }) => {
  const proceso = 'A_MODIFICAR_INFO_SERVICIO.PRO'
  let params = `?IN_ANIO=${anio}`
  params = params + `&IN_NSS=${nss}`
  params = params + `&IN_DNI=${dni}`
  params = params + `&IN_OBSERVACIONES=${observaciones}`
  params = params + `&IN_ORIGEN=${origen}`
  params = params + `&IN_DESTINO=${destino}`
  params = params + `&IN_PACIENTE=${paciente}`
  params = params + `&IN_SERVICIO_ID=${idServicio}`
  params = params + `&IN_TABLA=${tabla}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => {
    data.then(data => {
      const datosLogin = JSON.parse(sessionStorage.getItem('state'))
      const inCuadranteId = datosLogin.loginReducer.inCuadranteId
      const inIndicativoServicioId = datosLogin.loginReducer.inIndicativoServicioId
      data.TABLA === 'SERVICIOS_PENDIENTES'
        ?
        data.TIPO_SERVICIO_TXT === 'GENERICO'
          ?
          dispatch(obtenerServiciosGenericos(inCuadranteId, inIndicativoServicioId))
          :
          dispatch(obtenerServiciosActuales(inIndicativoServicioId, inCuadranteId))
        :
        dispatch(verServicio(data))
    })
  }
}

export const obtenerMaterialConsumido = (anio, idServicio, esPendiente) => {
  const proceso = 'A_CONSUMO_MATERIAL_SERVICIO.PRO'
  let params = `?IN_SERVICIO_ACTUAL_ANIO=${anio}`
  params = params + `&IN_SERVICIO_ACTUAL_ID=${idServicio}`
  params = params + `&IN_SERVICIO_ACTUAL_ES_PENDIENTE=${esPendiente}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url, false)
  return (dispatch) => { data.then(data => { dispatch(listaMaterialConsumido(data.LISTA_MATERIAL)) }) }
}

export const obtenerMaterialSeleccionable = () => {
  const proceso = 'A_MATERIAL_CONSUMO_TIPOS.PRO'
  const url = `${process.env.REACT_APP_APIVEL}${proceso}`
  const data = fetchData(url, false)
  return (dispatch) => { data.then(data => { dispatch(listaMaterialSeleccionable(data.LISTA_MATERIALES_CONSUMO)) }) }
}

export const enviarNuevoConsumoMaterial = (anio, idServicio, esPendiente, idMaterial, cantidad) => {
  const proceso = 'A_NUEVO_CONSUMO_MATERIAL.PRO'
  let params = `?IN_SERVICIO_ACTUAL_ANIO=${anio}`
  params = params + `&IN_SERVICIO_ACTUAL_ID=${idServicio}`
  params = params + `&IN_SERVICIO_ACTUAL_ES_PENDIENTE=${esPendiente}`
  params = params + `&IN_MATERIAL_CONSUMO_ID=${idMaterial}`
  params = params + `&IN_MATERIAL_CONSUMO_CANTIDAD=${cantidad}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url, false)
  return (dispatch) => { data.then(data => { dispatch(listaMaterialConsumido(data.LISTA_MATERIAL)) }) }
}

export const enviarNuevaIncidencia = ({ tabla, anio, idServicio, observaciones }) => {
  const proceso = 'A_CREAR_INCIDENCIA_SERVICIO.PRO'
  let params = `?IN_TABLA=${tabla}`
  params = params + `&IN_ANIO=${anio}`
  params = params + `&IN_SERVICIO_ID=${idServicio}`
  params = params + `&IN_OBSERVACIONES=${observaciones}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  console.log(url)
  const data = fetchData(url, false)
  return () => { data.then(data => { }) }
}

export const verListaServicios = (data) => {
  return {
    type: types.listarServicios,
    payload: {
      listaServicios: data
    }
  }
}

export const verServicio = (data) => {
  return {
    type: types.verServicio,
    payload: {
      servicio: data
    }
  }
}

export const verServiciosActuales = (servicioActuales, ultimoServicioActualModificado) => {
  return {
    type: types.verServiciosActuales,
    payload: {
      serviciosActuales: servicioActuales,
      ultimoServicioActualModificado: ultimoServicioActualModificado
    }
  }
}

export const verServiciosGenericosActuales = (data) => {
  return {
    type: types.verServicioGenerico,
    payload: {
      serviciosGenericos: data,
    }
  }
}

export const listaMaterialConsumido = (data) => {
  return {
    type: types.listarMaterialConsumido,
    payload: {
      materialConsumido: data
    }
  }
}

export const listaMaterialSeleccionable = (data) => {
  return {
    type: types.listarMaterialSeleccionable,
    payload: {
      materialSeleccionable: data
    }
  }
}
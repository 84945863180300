import React from 'react'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { InformeDeGolpeForm } from '../components/InformeDeGolpeForm'
// import { SolicitudCambioTurnoForm } from '../components/SolicitudCambioTurnoForm'
// import { NavLink } from 'react-router-dom'

export const InformeDeGolpe = () => {
  return (
    <PageLayout>
      <div className='content d-flex flex-column'>
        <TitlePage title='INFORME DE GOLPE' />
        <InformeDeGolpeForm />
      </div>
    </PageLayout>
  )
}
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerListaContactos, cargarListaContactos } from '../redux/actions/contactosActions'
import { TitlePage } from '../components/TitlePage'
import { Contacto } from '../components/Contacto'
import { PageLayout } from '../components/PageLayout'
import uniqid from 'uniqid'

export const Contactos = () => {
  const dispatch = useDispatch()

  const { contactos } = useSelector(({ contactosReducer }) => contactosReducer)
  const [listaContactos, setListaContactos] = useState(contactos)
  useEffect(() => {
    const cargarContactos = () => {
      dispatch(obtenerListaContactos())
    }
    const limpiarListaContactos = () => {
      dispatch(cargarListaContactos([]))
    }
    cargarContactos()
    return () => {
      limpiarListaContactos()
    }
  }, [dispatch])

  useEffect(() => {
    setListaContactos(contactos)
  }, [contactos])

  const handleInputChange = (e) => {
    if (e.target.value.length > 0) {
      setListaContactos(listaContactos.filter(contacto => contacto.INDICATIVO_SERVICIO.indexOf(e.target.value.toUpperCase()) !== -1));
    } else {
      setListaContactos(contactos);
    }
  }

  return (
    <PageLayout>
      <div className='container-fluid'>
        <div className='content'>
          <TitlePage title='CONTACTOS' />
          <div className='text-end mb-3'>
            <button className='btn btn-primary' onClick={() => window.location.reload()}>ACTUALIZAR</button>
          </div>
          <div className='d-flex justify-content-end align-items-'>
            <input
              type='text'
              className='form-control'
              placeholder='Escriba un texto para buscar'
              onChange={handleInputChange}
              autoFocus
            />
          </div>
          <div className='mt-3'>
            {
              listaContactos.length > 0
              &&
              <ul className='list-group shadow'>
                {
                  listaContactos.map(contacto => {
                    return (
                      <Contacto key={uniqid()} contacto={contacto} />
                    )
                  })
                }
              </ul>
            }
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

import React from 'react'
import { CalidadConcepto } from './CalidadConcepto'

export const CalidadTipoConcepto = ({ idTipoConcepto, nombreTipoConcepto, preguntas, registroCalidadId, arriba }) => {
  return (
    <div className='accordion-item' name={`heading${idTipoConcepto}`}>
      <h2 className='accordion-header' id={`heading${idTipoConcepto}`}>
        <button className='accordion-button collapsed' type='button' onClick={arriba} data-bs-toggle='collapse' data-bs-target={`#collapse${idTipoConcepto}`} aria-expanded='true' aria-controls={`collapse${idTipoConcepto}`}>
          {nombreTipoConcepto}
        </button>
      </h2>
      <div id={`collapse${idTipoConcepto}`} className='accordion-collapse collapse' aria-labelledby={`heading${idTipoConcepto}`} data-bs-parent='#accordionTipoConceptoCalidad'>
        <div className='accordion-body'>
          {
            preguntas.map(pregunta => {
              return (
                <CalidadConcepto key={pregunta.IN_CODIGO_PREGUNTA_ID} arriba={arriba} pregunta={pregunta} registroCalidadId={registroCalidadId} />
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
import React from 'react'
import { NavLink } from 'react-router-dom'

export const CitaTaller = ({ citaTaller }) => {
  return (
    <div className='card mt-3'>
      <div className='card-header card-header-primary'>
        <div className='d-flex'>
          <div className='me-auto'><span className={citaTaller.ESTADO_CITA === 'ABIERTA' ? 'text-success fw-bold' : 'text-secondary fw-bold'}>{citaTaller.ESTADO_CITA}</span></div>
          <div>{`Solicitada el ${citaTaller.FECHAHORA_SOLICITUD}`}</div>
        </div>
      </div>
      <div className='card-body py-4 text-center'>
        <span className='fw-bold'>FECHA CITA</span>
        <br />
        {`${citaTaller.FECHAHORA_CITA === '' ? 'Pendiente de asignación' : citaTaller.FECHAHORA_CITA}`}
      </div>
      <div className='card-footer'>
        <div className='d-flex'>
          <div className='me-auto'>{citaTaller.KMS_CITA !== 0 && `${citaTaller.KMS_CITA} Kms.`}</div>
          <div>
            <NavLink to={`/ver-cita-taller/${citaTaller.IN_CITATALLER_ID}`} className='btn btn-primary me-1'>
              VER CITA
            </NavLink>
          </div>
        </div>
      </div>
    </div >
  )
}

import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { nuevaReposicionMaterial, obtenerListaAlmacenes } from '../redux/actions/stockMaterialActions'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { obtenerMaterialSeleccionable } from '../redux/actions/serviciosActions'

export const StockMaterialReponer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { listaAlmacenes } = useSelector(({ stockMaterialReducer }) => stockMaterialReducer)
  const { materialSeleccionable } = useSelector(({ serviciosReducer }) => serviciosReducer)
  const { inCamilleroId, inConductorId, inIndicativoServicioId, inVehiculoId } = useSelector(({ loginReducer }) => loginReducer)

  useEffect(() => {
    dispatch(obtenerListaAlmacenes())
    dispatch(obtenerMaterialSeleccionable())
  }, [dispatch])

  const formik = useFormik({
    initialValues: {
      almacen: '',
      material: '',
      cantidad: 0,
      camilleroId: inCamilleroId,
      conductorId: inConductorId,
      indicativoServicioId: inIndicativoServicioId,
      vehiculoId: inVehiculoId
    },
    validationSchema: Yup.object(
      {
        almacen: Yup.string().required('Campo requerido.'),
        material: Yup.string().required('Campo requerido.'),
        cantidad: Yup.number().required('Campo requerido').min(1),
      }
    ),
    onSubmit: values => {
      dispatch(nuevaReposicionMaterial(formik.values))
      navigate(`/material-vehiculo`)
    },
  })

  return (
    <PageLayout>
      <div className='content d-flex flex-column'>
        <TitlePage title='NUEVA REPOSICION DE MATERIAL' />
        <div className='px-3'>
          <form id='nueva-reposicion-material-form' onSubmit={formik.handleSubmit}>

            <input type='hidden' name='camilleroId' value={formik.camilleroId} />
            <input type='hidden' name='conductorId' value={formik.conductorId} />
            <input type='hidden' name='indicativoServicioId' value={formik.inIndicativoServicioId} />
            <input type='hidden' name='vehiculoId' value={formik.inVehiculoId} />

            <div className='row mb-3'>
              <div className='col'>
                <label htmlFor='almacen' className='form-label'>Seleccione un almacén</label>
                <select
                  className={`form-select ${(formik.touched.almacen && formik.errors.almacen) && 'form-control-error'}`}
                  id='almacen'
                  name='almacen'
                  value={formik.values.almacen}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value=''>Seleccione una opción de la lista...</option>
                  {
                    listaAlmacenes.map(almacen => {
                      return (
                        <option key={almacen.IN_ALMACEN_ID} value={almacen.IN_ALMACEN_ID}>{almacen.NOMBRE_ALMACEN}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col'>
                <label htmlFor='material' className='form-label'>Seleccione material</label>
                <select
                  className={`form-select ${(formik.touched.material && formik.errors.material) && 'form-control-error'}`}
                  id='material'
                  name='material'
                  value={formik.values.material}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value=''>Seleccione una opción de la lista...</option>
                  {
                    materialSeleccionable.map(material => {
                      return (
                        <option key={material.IN_MATERIAL_CONSUMO_ID} value={material.IN_MATERIAL_CONSUMO_ID}>{material.IN_MATERIAL_CONSUMO_DESCRIPCION}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-sm-4'>
                <label htmlFor='cantidad' className='form-label'>Cantidad</label>
                <input
                  type='number'
                  className={`form-control form-control-sm ${(formik.touched.cantidad && formik.errors.cantidad) && 'form-control-error'}`}
                  id='cantidad'
                  name='cantidad'
                  value={formik.values.cantidad}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

          </form>
        </div>
        <div className='text-end mt-auto pt-3 px-3'>
          <NavLink to='/material-vehiculo' className='btn btn-primary me-1'>CANCELAR</NavLink>
          <button type='submit' form='nueva-reposicion-material-form' className='btn btn-primary'>ACEPTAR</button>
        </div>
      </div>
    </PageLayout>
  )
}

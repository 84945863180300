import { types } from '../types'
import { fetchData } from '../fetchData'

export const obtenerListaRegistrosCalidad = (inVehiculoId) => {
  const proceso = 'A_REGISTROS_CALIDAD_VER.PRO'
  let params = `?IN_VEHICULO_ID=${inVehiculoId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => {
    data.then(data => {
      dispatch(listarRegistrosCalidad(data.LISTA_REGISTROSCALIDAD))
    })
  }
}

export const obtenerDatosRegistroCalidad = (registroCalidadId, mostrarSpinner = true) => {
  const proceso = 'A_REGISTRO_CALIDAD_MOSTRAR.PRO'
  let params = `?IN_REGISTRO_CALIDAD_ID=${registroCalidadId}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url, mostrarSpinner)
  return (dispatch) => {
    data.then(data => {
      dispatch(verRegistroCalidad(data))
    })
  }
}

export const modificarConceptoRegistroCalidad = (inputField) => {
  const valorFecha = inputField.esFechaCaducidad === 'SI' ? inputField[inputField.preguntaId + '-fecha'] : ''
  const valorNumerico = inputField[inputField.preguntaId + '-cantidad']
  const valorSiNo = inputField[inputField.preguntaId + '-sino']

  const proceso = 'A_REGISTRO_CALIDAD_MODIFICA_CONCEPTO.PRO'
  let params = `?IN_REGISTRO_CALIDAD_ID=${inputField.registroCalidadId}`
  params = params + `&IN_CONCEPTO_ID=${inputField.preguntaId}`
  params = params + `&IN_FECHA_CADUCIDAD=${valorFecha}`
  params = params + `&IN_SI_NO=${valorSiNo === 'SI' ? 'ON' : 'OFF'}`
  params = params + `&IN_VALOR_NUMERICO=${valorNumerico}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url, false)
  return (dispatch) => {
    data.then(data => {
      dispatch(obtenerDatosRegistroCalidad(inputField.registroCalidadId, false))
    })
  }
}

export const actualizarKmsProximoMantenimiento = (valorNumerico, registroCalidadId) => {
  const preguntaId = 0
  const valorFecha = ""
  const valorSiNo = 'NO'

  const proceso = 'A_REGISTRO_CALIDAD_MODIFICA_CONCEPTO.PRO'
  let params = `?IN_REGISTRO_CALIDAD_ID=${registroCalidadId}`
  params = params + `&IN_CONCEPTO_ID=${preguntaId}`
  params = params + `&IN_FECHA_CADUCIDAD=${valorFecha}`
  params = params + `&IN_SI_NO=${valorSiNo === 'SI' ? 'ON' : 'OFF'}`
  params = params + `&IN_VALOR_NUMERICO=${valorNumerico}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url, false)
  return (dispatch) => {
    data.then(data => {
      dispatch(obtenerDatosRegistroCalidad(registroCalidadId, false))
    })
  }
}


export const listarRegistrosCalidad = (data) => {
  return {
    type: types.listarRegistrosCalidad,
    payload: {
      listaRegistrosCalidad: data
    }
  }
}

export const verRegistroCalidad = (data) => {
  return {
    type: types.verRegistroCalidad,
    payload: {
      registroCalidad: data
    }
  }
}
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ServicioInformacionModal } from '../components/ServicioInformacionModal'

export const ServicioInformacion = ({ servicio }) => {
  return new Promise((resolve, reject) => {
    showModal(resolve, reject, servicio)
  })
}

const showModal = (resolve, reject, servicio) => {
  const body = document.getElementsByTagName('body')[0]
  const div = document.createElement('div')
  div.setAttribute('id', 'modal-container')
  body.appendChild(div)
  const modalContainer = ReactDOM.createRoot(div)
  modalContainer.render(<ServicioInformacionModal resolve={resolve} reject={reject} servicio={servicio} />)
}
import { types } from '../types'
import { fetchData } from '../fetchData'

export const enviarDatosSolicitudCambioTurno = ({
  ind_solicitante,
  email_solicitante,
  tlf_solicitante,
  ind_cubreturno,
  ind_servicio_cubrir,
  motivo,
  fecha_inicio_cobertura,
  hora_inicio_cobertura,
  fecha_fin_cobertura,
  hora_fin_cobertura }) => {
  const proceso = 'A_COMPRUEBA_CAMBIO_TURNO.PRO'
  let params = `?IND_SOLICITANTE=${ind_solicitante}`
  params = params + `&EMAIL_SOLICITANTE=${email_solicitante}`
  params = params + `&TLF_SOLICITANTE=${tlf_solicitante}`
  params = params + `&IND_CUBRETURNO=${ind_cubreturno}`
  params = params + `&IND_SERVICIO_CUBRIR=${ind_servicio_cubrir}`
  params = params + `&MOTIVO=${motivo}`
  params = params + `&FECHA_INICIO_COBERTURA=${fecha_inicio_cobertura}`
  params = params + `&HORA_INICIO_COBERTURA=${hora_inicio_cobertura}`
  params = params + `&FECHA_FIN_COBERTURA=${fecha_fin_cobertura}`
  params = params + `&HORA_FIN_COBERTURA=${hora_fin_cobertura}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => {
    data.then(data => {
      dispatch(datosSolicitudCambioTurno(data))
    })
  }
}

export const datosSolicitudCambioTurno = (data) => {
  return {
    type: types.solicitarCambioturno,
    payload: {
      respuestaSolicitudCambioTurno: data.CREADO,
      datosCubreTurno: data.DATOS_CUBRE_TURNO,
      datosSolicitante: data.DATOS_SOLICITANTE
    }
  }
}
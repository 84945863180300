import { types } from '../types'

const initialValues = {
  contactos: []
}

export const contactosReducer = (state = initialValues, action) => {
  switch (action.type) {
    case types.cargarListaContactos:
      return {
        contactos: action.payload.contactos,
      }
    default:
      return state
  }
}
import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

export const Repostaje = ({ repostaje }) => {
  const { inVehiculoId } = useSelector(({ loginReducer }) => loginReducer)

  return (
    <div className='card mt-3 mx-3'>
      <div className='card-header'>
        <div className='d-flex fw-bold'>
          <div className='me-auto'>{repostaje.REPOSTAJE_FECHA}</div>
          <div>{`${repostaje.IN_REPOSTAJE_IMPORTE} €`}</div>
        </div>
      </div>
      <div className='card-body py-4 text-center'>{repostaje.REPOSTAJE_LUGAR}
      </div>
      <div className='card-footer'>
        <div className='d-flex'>
          <div className='me-auto'>{`${repostaje.REPOSTAJE_TIPOCOMBUSTIBLE} (${repostaje.IN_REPOSTAJE_LITROS} Litros)`}</div>
          <div>
            <NavLink to={`/repostaje/${inVehiculoId}/${repostaje.IN_REPOSTAJE_ID}`} className='btn btn-primary'>
              VER REPOSTAJE
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

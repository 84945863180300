import React from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { actualizarKmsProximoMantenimiento } from '../redux/actions/calidadActions'

export const CalidadActualizarKmsProximoMantenimiento = ({ kmsProximoMantenimiento, registroCalidadId }) => {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      kmsProximoMantenimiento: kmsProximoMantenimiento,
    },
    validationSchema: Yup.object(
      {
        kmsProximoMantenimiento: Yup.string().required('Campo requerido').matches(/^[0-9]+([0-9]+)?$/, 'Formato incorrecto.'),
      }
    ),
    onSubmit: values => {
      document.getElementById('submit').classList.add('no-show')
      document.getElementById('spinner').classList.remove('no-show')
      setTimeout(async () => {
        dispatch(actualizarKmsProximoMantenimiento(formik.values.kmsProximoMantenimiento, registroCalidadId))
        document.getElementById('spinner').classList.add('no-show')
        document.getElementById('submit').classList.remove('no-show')
      }, 1000)
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit} id="form">
        <label htmlFor='kmsProximomantenimiento' className='form-label'>Kms. Próximo mantenimiento</label>
        <div className='input-group'>
          <input
            className={`form-control form-control-sm ${(formik.touched.kmsProximoMantenimiento && formik.errors.kmsProximoMantenimiento) && 'form-control-error'}`}
            type="text"
            name="kmsProximoMantenimiento"
            id="kmsProximoMantenimiento"
            value={formik.values.kmsProximoMantenimiento}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <button type="submit" className='btn btn-sm btn-primary' id="submit">ACTUALIZAR KMS</button>
          <button className='btn btn-primary btn-sm no-show' type='button' id='spinner'>
            <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
            ENVIANDO...
          </button>
        </div>
      </form>
    </>
  )
}
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerMaterialConsumido, obtenerMaterialSeleccionable, enviarNuevoConsumoMaterial } from '../redux/actions/serviciosActions'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import uniqid from 'uniqid'
import 'animate.css'

export const ServicioConsumoMaterialForm = ({ resolve, reject, servicio }) => {
  const dispatch = useDispatch()
  const { materialConsumido, materialSeleccionable } = useSelector(({ serviciosReducer }) => serviciosReducer)

  useEffect(() => {
    dispatch(obtenerMaterialSeleccionable())
    dispatch(obtenerMaterialConsumido(servicio.IN_ANIO, servicio.IN_SERVICIO_ID, servicio.TABLA === 'SERVICIOS_PENDIENTES' ? '1' : '0'))
  }, [dispatch, servicio.IN_ANIO, servicio.IN_SERVICIO_ID, servicio.TABLA])

  const cancel = () => {
    resolve('')
    closeModal()
  }

  const closeModal = () => {
    const element = document.getElementById('modal-container')
    element.parentNode.removeChild(element)
  }

  const formik = useFormik({
    initialValues: {
      material: '',
      cantidad: 0
    },
    validationSchema: Yup.object(
      {
        material: Yup.string().required('Campo requerido.'),
        cantidad: Yup.number().required('Campo requerido').min(1),
      }
    ),
    onSubmit: values => {
      dispatch(enviarNuevoConsumoMaterial(servicio.IN_ANIO, servicio.IN_SERVICIO_ID, servicio.TABLA === 'SERVICIOS_PENDIENTES' ? '1' : '0', values.material, values.cantidad))
      document.getElementById('cantidad').focus()
    },
  })
  return (
    <div className='modal-card-container d-flex justify-content-center align-items-center'>
      <div className='card modal-card modal-card-xl animate__animated animate__fadeIn'>
        <div className='card-header card-header-primary'>CONSUMO DE MATERIAL</div>
        <div className='card-body'>
          <div className='mt-3 mb-5'>
            <h5>AÑADIR MATERIAL A LA LISTA</h5>
            <form id='nuevoConsumoMaterialForm' className='row g3' onSubmit={formik.handleSubmit}>
              <div className='col-2'>
                <input
                  type="number"
                  className={`form-control ${(formik.touched.cantidad && formik.errors.cantidad) && 'input-border-error'}`}
                  id="cantidad"
                  name="cantidad"
                  value={formik.values.cantidad}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoFocus={true}
                />
              </div>
              <div className='col-8'>
                <select
                  className={`form-select ${(formik.touched.material && formik.errors.material) && 'input-border-error'}`}
                  id="material"
                  name="material"
                  value={formik.values.material}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Seleccione material de la lista...</option>
                  {
                    materialSeleccionable.map(material => {
                      return (
                        <option key={material.IN_MATERIAL_CONSUMO_ID} value={material.IN_MATERIAL_CONSUMO_ID}>{material.IN_MATERIAL_CONSUMO_DESCRIPCION}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className='col-2 align-bottom'>
                <button className='btn btn-primary' form='nuevoConsumoMaterialForm' type='submit'>AÑADIR</button>
              </div>

            </form>
          </div>
          <div id='container-tabla-material-consumido'>

            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Material consumido</th>
                  <th scope="col" className="text-end">Cantidad - código</th>
                </tr>
              </thead>
              <tbody>
                {
                  materialConsumido.length > 0
                  &&
                  materialConsumido.map(material => {
                    return (
                      <tr key={uniqid()}>
                        <td>{material.MATERIAL_DESCRIPCION}</td>
                        <td className="text-end">{material.MATERIAL_CANTIDAD}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className='card-footer text-end'>
          <button className='btn btn-primary ms-1' onClick={cancel}>CERRAR</button>
        </div>
      </div>
    </div>
  )
}

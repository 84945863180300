import { types } from '../types'

const initialValues = {
  isLoggedIn: false,
  loginError: false,
  logoutError: false,
  camilleroIndicativo: '',
  conductorIndicativo: '',
  esUrgencia: 0,
  permitirGestionServiciosTelefono: false,
  mostrarColectivo: 0,
  esServicioPreventivo: 0,
  indicativoServicioNombre: '',
  inCamilleroId: 0,
  inConductorId: 0,
  inCuadranteId: 0,
  inIndicativoServicioId: 0,
  inVehiculoId: 0,
  mensajeAvisos: '',
  vehiculoMatricula: '',
  dispositivo: '',
  pedirFotosInicioJornada: false
}

export const loginReducer = (state = initialValues, action) => {
  switch (action.type) {
    case types.login:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        servicioMedicoEnfermeria: action.payload.servicioMedicoEnfermeria,
        loginError: action.payload.loginError,
        camilleroIndicativo: action.payload.camilleroIndicativo,
        conductorIndicativo: action.payload.conductorIndicativo,
        esUrgencia: action.payload.esUrgencia,
        permitirGestionServiciosTelefono: action.payload.permitirGestionServiciosTelefono,
        mostrarColectivo: action.payload.mostrarColectivo,
        esServicioPreventivo: action.payload.esServicioPreventivo,
        indicativoServicioNombre: action.payload.indicativoServicioNombre,
        inCamilleroId: action.payload.inCamilleroId,
        inConductorId: action.payload.inConductorId,
        inCuadranteId: action.payload.inCuadranteId,
        inIndicativoServicioId: action.payload.inIndicativoServicioId,
        inVehiculoId: action.payload.inVehiculoId,
        mensajeAvisos: action.payload.mensajeAvisos,
        vehiculoMatricula: action.payload.vehiculoMatricula,
        dispositivo: action.payload.dispositivo,
        pedirFotosInicioJornada: action.payload.pedirFotosInicioJornada
      }
    case types.loginError:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        loginError: action.payload.loginError
      }
    case types.logout:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
      }
    case types.logoutError:
      return {
        ...state,
        logoutError: action.payload.logoutError
      }
    case types.actualizarDatosVehiculo:
      return {
        ...state,
        vehiculoMatricula: action.payload.vehiculoMatricula,
        inVehiculoId: action.payload.inVehiculoId,
        inCuadranteId: action.payload.inCuadranteId,
        mensajeAvisos: action.payload.mensajeAvisos
      }
    case types.actualizarMensajeAvisos:
      return {
        ...state,
        mensajeAvisos: action.payload.mensajeAvisos
      }
    case types.actualizarPedirFotosInicioJornada:
      return {
        ...state,
        pedirFotosInicioJornada: action.payload.pedirFotosInicioJornada
      }
    default:
      return state
  }
}
import { types } from '../types'
import { fetchData } from '../fetchData'

export const obtenerListaAlmacenesOxigeno = () => {
  const proceso = 'A_OXIGENO_CARGA_ALMACENES.PRO'
  const url = `${process.env.REACT_APP_APIVEL}${proceso}`
  const data = fetchData(url)
  return (dispatch) => {
    data.then(data => { dispatch(listaAlmacenesOxigeno(data)) })
  }
}

export const enviarDatosRecogerOxigeno = ({ inConductorId, inIndicativoServicioId, inVehiculoId, almacenOxigeno, codigoBotella, fechaCaducidad }) => {
  const proceso = 'A_OXIGENO_RECOGIDA.PRO'
  let params = `?IN_CONDUCTOR_ID=${inConductorId}`
  params = params + `&IN_INDICATIVO_SERVICIO_ID=${inIndicativoServicioId}`
  params = params + `&IN_VEHICULO_ID=${inVehiculoId}`
  params = params + `&IN_CODIGO_BOTELLA=${codigoBotella}`
  params = params + `&IN_ALMACEN_ID=${almacenOxigeno}`
  params = params + `&IN_FECHA_CADUCIDAD=${fechaCaducidad}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => {
    data.then(data => { dispatch(recogerOxigeno(data.RETORNO)) })
  }
}

export const enviarDatosDevolverOxigeno = ({ inConductorId, inIndicativoServicioId, inVehiculoId, almacenOxigeno, codigoBotella, litrosBotella }) => {
  const proceso = 'A_OXIGENO_DEVOLVER.PRO'
  let params = `?IN_CONDUCTOR_ID=${inConductorId}`
  params = params + `&IN_INDICATIVO_SERVICIO_ID=${inIndicativoServicioId}`
  params = params + `&IN_VEHICULO_ID=${inVehiculoId}`
  params = params + `&IN_CODIGO_BOTELLA=${codigoBotella}`
  params = params + `&IN_ALMACEN_ID=${almacenOxigeno}`
  params = params + `&IN_LITROS=${litrosBotella}`
  const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
  const data = fetchData(url)
  return (dispatch) => {
    data.then(data => { dispatch(devolverOxigeno(data.RETORNO)) })
  }
}

export const listaAlmacenesOxigeno = (data) => {
  return {
    type: types.listarAlmacenesOxigeno,
    payload: {
      listaAlmacenesOxigeno: data
    }
  }
}

export const recogerOxigeno = (data) => {
  return {
    type: types.recogerOxigeno,
    payload: {
      respuestaRecogerOxigeno: String(data)
    }
  }
}

export const devolverOxigeno = (data) => {
  return {
    type: types.devolverOxigeno,
    payload: {
      respuestaDevolverOxigeno: String(data)
    }
  }
}
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { obtenerServiciosGenericos, verServiciosGenericosActuales } from '../redux/actions/serviciosActions'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { AlertaListaSinResultados } from '../components/AlertaListaSinResultados'
import { ServicioForm } from '../components/ServicioForm'
import moment from 'moment/moment'
import { AlertaServiciosDesdeMovil } from '../components/AlertaServiciosDesdeMovil'

export const ServiciosGenericos = () => {
  const dispatch = useDispatch()
  const { inIndicativoServicioId, inCuadranteId, permitirGestionServiciosTelefono } = useSelector(({ loginReducer }) => loginReducer)
  const { serviciosGenericos } = useSelector(({ serviciosReducer }) => serviciosReducer)

  const orientation = window.screen.orientation.type
  const widthScreen = window.screen.width
  const heightScreen = window.screen.height

  let esPantallaTelefono = false

  if ((orientation === 'landscape-primary' && widthScreen < Number(750) && heightScreen < Number(370)) || (orientation === 'portrait-primary' && widthScreen < Number(370) && heightScreen < Number(750))) {
    esPantallaTelefono = true
  }

  useEffect(() => {
    dispatch(obtenerServiciosGenericos(inCuadranteId, inIndicativoServicioId))
    return () => {
      dispatch(verServiciosGenericosActuales([]))
    }
  }, [dispatch, inCuadranteId, inIndicativoServicioId])

  const getButtonState = (dateTimeText, estadoServicio) => {
    if ((estadoServicio === 'FINALIZADO') || (estadoServicio === 'PENDIENTE FINALIZACION') || (estadoServicio === 'ANULADO') || ((dateTimeText !== '') && (moment(dateTimeText, 'DD-MM-YYYY hh:mm').isSameOrAfter(moment('01-01-2000 00:00', 'DD-MM-YYYY hh:mm'))))) {
      return 'disabled'
    } else {
      return ''
    }
  }

  const getDateTimeText = (dateTimeText) => {
    if ((dateTimeText === '') || (moment(dateTimeText, 'DD-MM-YYYY hh:mm').isBefore(moment('01-01-2000 00:00', 'DD-MM-YYYY hh:mm')))) {
      return ('--/--/---- --:--')
    } else {
      return (dateTimeText)
    }
  }

  return (
    <PageLayout>
      <div className='content d-flex flex-column'>
        <TitlePage title='SERVICIOS PREVENTIVOS/EXTRAS' />
        {/* {
          !esPantallaTelefono || (esPantallaTelefono && permitirGestionServiciosTelefono)
            ?
            <> */}
        <div className="text-end mb-3 px-3">
          <button className='btn btn-primary' onClick={() => window.location.reload()}>ACTUALIZAR</button>
        </div>
        {
          serviciosGenericos.length > 0
            ?
            serviciosGenericos.map(servicio => {
              return (
                <ServicioForm
                  key={servicio.IN_SERVICIO_GENERICO_ID}
                  servicio={
                    {
                      "GESTION_FIN_SERVICIO": {
                        "CODIGO_FINALIZACION": 0,
                        "KMS_RECORRIDOS": 0,
                        "PACIENTES_C_SILLA": 0,
                        "PACIENTES_SIN_SILLA": 0
                      },
                      "GESTION_HORAS": {
                        "ANULAR": servicio.GESTION_HORAS.ANULAR,
                        "FINALIZADO": servicio.GESTION_HORAS.FINALIZADO,
                        "HORA_ACTIVACION": servicio.GESTION_HORAS.HORA_ACTIVACION,
                        "HORA_SERVICIO": servicio.GESTION_HORAS.HORA_SERVICIO,
                        "INICIO_SERVICIO": servicio.GESTION_HORAS.INICIO_SERVICIO,
                        "LIBRE_RETORNANDO": servicio.GESTION_HORAS.LIBRE_RETORNANDO,
                        "LLEGADA_DESTINO": servicio.GESTION_HORAS.LLEGADA_DESTINO,
                        "LLEGADA_LUGAR": servicio.GESTION_HORAS.LLEGADA_LUGAR,
                        "REALIZANDOSE": servicio.GESTION_HORAS.REALIZANDOSE,
                        "RESOLUCION_IN_SITU": servicio.GESTION_HORAS.RESOLUCION_IN_SITU,
                        "SALIDA_LUGAR": servicio.GESTION_HORAS.SALIDA_LUGAR
                      },
                      "IN_ANIO": servicio.IN_ANIO_SRV_GENERICO,
                      "IN_SERVICIO_ID": servicio.IN_SERVICIO_GENERICO_ID,
                      "SERVICIO_DESTINO": '',
                      "SERVICIO_ESTADO": servicio.SERVICIO_ESTADO,
                      "SERVICIO_ES_URGENCIA": servicio.SERVICIO_ES_URGENCIA,
                      "INPUTS_FORMULARIO": [
                        {
                          "POSICION": 1,
                          "VISIBLE": servicio.GESTION_HORAS.INICIO_SERVICIO === 1 ? true : false,
                          "NOMBRE": "INICIO SERVICIO",
                          "VALOR": getDateTimeText(servicio.SERVICIO_FECHA_HORA_INICIO),
                          "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHA_HORA_INICIO, servicio.SERVICIO_ESTADO),
                          "MOSRTAR_BOTON": true,
                          "CODIGO_HORA": 300
                        },
                        {
                          "POSICION": 2,
                          "VISIBLE": servicio.GESTION_HORAS.LLEGADA_LUGAR === 1 ? true : false,
                          "NOMBRE": "UBICACION",
                          "VALOR": getDateTimeText(servicio.SERVICIO_FECHA_HORA_UBICACION),
                          "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHA_HORA_UBICACION, servicio.SERVICIO_ESTADO),
                          "MOSRTAR_BOTON": true,
                          "CODIGO_HORA": 500
                        },
                        {
                          "POSICION": 3,
                          "VISIBLE": servicio.GESTION_HORAS.REALIZANDOSE === 1 ? true : false,
                          "NOMBRE": "INICIO EVENTO",
                          "VALOR": getDateTimeText(servicio.SERVICIO_FECHA_HORA_COMIENZO_EVENTO),
                          "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHA_HORA_COMIENZO_EVENTO, servicio.SERVICIO_ESTADO),
                          "MOSRTAR_BOTON": true,
                          "CODIGO_HORA": 400
                        },
                        {
                          "POSICION": 4,
                          "VISIBLE": servicio.GESTION_HORAS.SALIDA_LUGAR === 1 ? true : false,
                          "NOMBRE": "SALIDA LUGAR",
                          "VALOR": getDateTimeText(servicio.SERVICIO_FECHA_HORA_SALIDA_LUGAR),
                          "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHA_HORA_SALIDA_LUGAR, servicio.SERVICIO_ESTADO),
                          "MOSRTAR_BOTON": true,
                          "CODIGO_HORA": 600
                        },
                        {
                          "POSICION": 5,
                          "VISIBLE": servicio.GESTION_HORAS.LLEGADA_DESTINO === 1 ? true : false,
                          "NOMBRE": "LLEGADA DESTINO",
                          "VALOR": getDateTimeText(servicio.SERVICIO_FECHA_HORA_LLEGADA_DESTINO),
                          "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHA_HORA_LLEGADA_DESTINO, servicio.SERVICIO_ESTADO),
                          "MOSRTAR_BOTON": true,
                          "CODIGO_HORA": 700
                        },
                        {
                          "POSICION": 6,
                          "VISIBLE": servicio.GESTION_HORAS.LIBRE_RETORNANDO === 1 ? true : false,
                          "NOMBRE": "FIN EVENTO",
                          "VALOR": getDateTimeText(servicio.SERVICIO_FECHA_HORA_FIN_EVENTO),
                          "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHA_HORA_FIN_EVENTO, servicio.SERVICIO_ESTADO),
                          "MOSRTAR_BOTON": true,
                          "CODIGO_HORA": 800
                        },
                        {
                          "POSICION": 7,
                          "VISIBLE": servicio.GESTION_HORAS.FINALIZADO === 1 ? true : false,
                          "NOMBRE": "FINALIZADO",
                          "VALOR": getDateTimeText(servicio.SERVICIO_FECHA_HORA_FINALIZADO),
                          "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHA_HORA_FINALIZADO, servicio.SERVICIO_ESTADO),
                          "MOSRTAR_BOTON": true,
                          "CODIGO_HORA": 900
                        },
                        {
                          "POSICION": 8,
                          "VISIBLE": servicio.GESTION_HORAS.ANULAR === 1 ? true : false,
                          "NOMBRE": "ANULADO",
                          "VALOR": getDateTimeText(servicio.SERVICIO_FECHA_HORA_ANULADO),
                          "ESTADO_BOTON": getButtonState(servicio.SERVICIO_FECHA_HORA_ANULADO, servicio.SERVICIO_ESTADO),
                          "MOSRTAR_BOTON": true,
                          "CODIGO_HORA": 1000
                        }
                      ],
                      "SERVICIO_FECHA_HORA_ANULADO": servicio.SERVICIO_FECHA_HORA_ANULADO,
                      "SERVICIO_FECHA_HORA_FINALIZADO": servicio.SERVICIO_FECHA_HORA_FINALIZADO,
                      "SERVICIO_FECHA_HORA_INICIO": servicio.SERVICIO_FECHA_HORA_UBICACION,
                      "SERVICIO_FECHA_HORA_LLEGADA_DESTINO": '',
                      "SERVICIO_FECHA_HORA_LLEGADA_LUGAR": '',
                      "SERVICIO_FECHA_HORA_PETICION": '',
                      "SERVICIO_FECHA_HORA_REALIZANDOSE": servicio.SERVICIO_FECHA_HORA_INICIO,
                      "SERVICIO_FECHA_HORA_RETORNANDO": servicio.SERVICIO_FECHA_HORA_FIN_SERVICIO,
                      "SERVICIO_FECHA_HORA_SALIDA_LUGAR": '',
                      "SERVICIO_FECHA_HORA_SERVICIO": servicio.SERVICIO_FECHA_HORA_SERVICIO,
                      "SERVICIO_NSS": '',
                      "SERVICIO_DNI": '',
                      "SERVICIO_OBSERVACIONES": servicio.SERVICIO_OBSERVACIONES,
                      "SERVICIO_ORIGEN": servicio.SERVICIO_ORIGEN,
                      "SERVICIO_PACIENTE": servicio.SERVICIO_PACIENTE,
                      "TABLA": servicio.TABLA,
                      "TIPO_SERVICIO_ID": servicio.TIPO_SERVICIO_ID,
                      "TIPO_SERVICIO_TXT": servicio.TIPO_SERVICIO_TXT,
                    }
                  }
                />
              )
            })
            :
            <AlertaListaSinResultados />
        }
        {/* </>
            :
            <AlertaServiciosDesdeMovil />
        } */}
      </div>
    </PageLayout>
  )
}
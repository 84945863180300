import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchData } from '../redux/fetchData'
import { fileToBase64 } from '../helpers/fileToBase64'
import { formatFileSize } from '../helpers/formatFileSize'
import { Confirm } from '../helpers/Confirm'
import { actualizarPedirFotosInicioJornada } from '../redux/actions/loginActions'
import photo from '../images/photo.png'

export const FotosInicioJornada = () => {
  const dispatch = useDispatch()
  const { inIndicativoServicioId, inCuadranteId } = useSelector(({ loginReducer }) => loginReducer)
  const [imagenInicioJornada, setImagenInicioJornada] = useState([
    { id: 1, title: 'FRONTAL', imagen: photo, estado: false },
    { id: 2, title: 'LATERAL CONDUCTOR', imagen: photo, estado: false },
    { id: 3, title: 'TRASERA', imagen: photo, estado: false },
    { id: 4, title: 'LATERAL ACOMPAÑANTE', imagen: photo, estado: false },
  ])

  useEffect(() => {
    const imagenesSubidas = imagenInicioJornada.filter(img => {
      return img.estado
    })
    if (imagenesSubidas.length === 4) {
      setTimeout(async () => {
        const resp = await Confirm('TODAS LAS IMAGENES SE HAN ENVIADO CORRECTAMENTE.', 'ACEPTAR')
        resp
          &&
          dispatch(actualizarPedirFotosInicioJornada(false))
      }, 100);
    }
  }, [dispatch, imagenInicioJornada])


  const uploadImage = async (numFoto, base64Image) => {
    document.getElementById(`pendingUploadedImage${numFoto}`).classList.remove('no-show')
    document.getElementById(`checkUploadedImage${numFoto}`).classList.add('no-show')
    document.getElementById(`errorUploadedImage${numFoto}`).classList.add('no-show')
    document.getElementById(`spinnerImagen${numFoto}`).classList.add('no-show')
    const proceso = 'A_INICIO_JORNADA_ADD_FOTO.PRO'
    let params = `?IN_INDICATIVO_SERVICIO_ID=${inIndicativoServicioId}`
    params = params + `&IN_CUADRANTE_ID=${inCuadranteId}`
    params = params + `&IN_NUMERO_IMAGEN=${numFoto}`
    params = params + `&IN_IMAGEN_VEHICULO=${base64Image}`
    const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
    const res = await fetchData(url)
    return res.ESTADO_ADD_FOTO
  }

  const setNuevaImagen = (numImagen, imageUrl, estadoAddImagen) => {
    document.getElementById(`pendingUploadedImage${numImagen}`).classList.add('no-show')
    const nuevaImagenInicioJornada = imagenInicioJornada.map(img => {
      if ((img.id.toString() === numImagen) && (estadoAddImagen)) {
        document.getElementById(`checkUploadedImage${numImagen}`).classList.remove('no-show')
        return { id: numImagen, title: img.title, imagen: imageUrl, estado: estadoAddImagen }
      } else if ((img.id.toString() === numImagen) && (!estadoAddImagen)) {
        document.getElementById(`errorUploadedImage${numImagen}`).classList.remove('no-show')
        return img
      }
      else {
        return img
      }
    })
    setImagenInicioJornada(nuevaImagenInicioJornada)
  }

  const setImage = async (e) => {
    const numImagen = e.target.getAttribute('data-num-foto')
    document.getElementById(`spinnerImagen${numImagen}`).classList.remove('no-show')
    const image = e.target.files[0]
    const imageUrl = URL.createObjectURL(image)
    const fileSize = formatFileSize(image.size)
    console.log(fileSize)

    const compressedImageInBase64 = await fileToBase64(image)
    const estadoAddImagen = await uploadImage(numImagen, compressedImageInBase64.split(',')[1])
    setNuevaImagen(numImagen, imageUrl, estadoAddImagen === '1' ? true : false)
  }

  const getImageFromCamera = (e) => {
    e.target.id === 'btnFoto1' && document.getElementById('inputFoto1FromCamera').click()
    e.target.id === 'btnFoto2' && document.getElementById('inputFoto2FromCamera').click()
    e.target.id === 'btnFoto3' && document.getElementById('inputFoto3FromCamera').click()
    e.target.id === 'btnFoto4' && document.getElementById('inputFoto4FromCamera').click()
  }

  return (
    <>
      {
        imagenInicioJornada.map((ft, index) => {
          return (
            <div key={index} className="card mb-3" id="card-foto-inicio-jornada">
              <div className="row g-0">
                <div className="col-md-8">
                  <div className="card-body d-flex flex-column">
                    <div className='d-flex'>
                      <div className='me-auto'>
                        <span id={`pendingUploadedImage${ft.id}`} className="px-3 py-2 badge text-bg-primary">PENDIENTE</span>
                        <span id={`checkUploadedImage${ft.id}`} className="px-3 py-2 badge text-bg-success no-show">CORRECTO</span>
                        <span id={`errorUploadedImage${ft.id}`} className="px-3 py-2  badge text-bg-danger no-show">ERROR</span>
                      </div>
                      <div className='mb-1'>
                        <h5 className="card-title">{ft.title}</h5>
                      </div>
                    </div>
                    <div>
                      <input
                        type='file'
                        className={`form-control form-control-sm`}
                        id={`inputFoto${ft.id}`}
                        name={`inputFoto${ft.id}`}
                        data-num-foto={ft.id}
                        accept='image/jpeg'
                        onChange={setImage}
                      />
                      <div className='text-center mt-3'>
                        <button type="button" className='btn btn-primary' id={`btnFoto${ft.id}`} onClick={getImageFromCamera}>CAPTURAR FOTO</button>
                      </div>
                      <input
                        type='file'
                        className={`form-control form-control-sm hide`}
                        id={`inputFoto${ft.id}FromCamera`}
                        name={`inputFoto${ft.id}FromCamera`}
                        data-num-foto={ft.id}
                        capture="camera"
                        accept='image/jpeg'
                        onChange={setImage}
                      />
                      <div className="d-flex justify-content-center">
                        <div id={`spinnerImagen${ft.id}`} className="spinner-border text-primary no-show" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <img src={imagenInicioJornada[ft.id - 1].imagen} id={`imgFoto${ft.id}`} className="img-fluid rounded mx-auto d-block img-inicio-jornada" alt="imagen inicio jornada" />
                </div>
              </div>
            </div>
          )
        })
      }
    </>
  )
}

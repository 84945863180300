import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PageLayout } from '../components/PageLayout'
import { TitlePage } from '../components/TitlePage'
import { CalidadRegistro } from '../components/CalidadRegistro'
import { AlertaListaSinResultados } from '../components/AlertaListaSinResultados'
import { Confirm } from '../helpers/Confirm'
import { obtenerListaRegistrosCalidad, listarRegistrosCalidad } from '../redux/actions/calidadActions'

export const CalidadRegistros = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { inVehiculoId, inIndicativoServicioId, mensajeAvisos } = useSelector(({ loginReducer }) => loginReducer)
  const { listaRegistrosCalidad } = useSelector(({ calidadReducer }) => calidadReducer)
  const kmsInicio = mensajeAvisos.KMS_INICIO

  useEffect(() => {
    dispatch(obtenerListaRegistrosCalidad(inVehiculoId))
    return () => {
      dispatch(listarRegistrosCalidad([]))
    }
  }, [dispatch, inVehiculoId])

  const nuevoRegistroCalidad = async () => {
    setTimeout(async () => {
      const resp = await Confirm('SE VA A CREAR UN NUEVO REGISTRO DE CALIDAD. ¿DESEA CONTINUAR?', 'ACEPTAR', 'CANCELAR')
      if (resp) {
        try {
          const url = `${process.env.REACT_APP_APIVEL}A_REGISTRO_CALIDAD_CREAR.PRO?IN_VEHICULO_ID=${inVehiculoId}&IN_INDICATIVO_SERVICIO_ID=${inIndicativoServicioId}&IN_KMS_INICIO=${kmsInicio}`
          const resp = await fetch(url)
          const data = await resp.json()
          navigate(`/registro-calidad/${data.IN_REGISTRO_CALIDAD_ID}`, { replace: true })
        } catch (error) {
          console.warn(error)
        }
      }
    }, 100)
  }

  return (
    <PageLayout>
      <div className='content'>
        <TitlePage title='REGISTROS DE CALIDAD' />
        <div className='text-end mb-3 px-3'>
          <button className='btn btn-primary me-1' onClick={nuevoRegistroCalidad}>NUEVO REGISTRO DE CALIDAD</button>
          <button className='btn btn-primary' onClick={() => window.location.reload()}>ACTUALIZAR</button>
        </div>
        <div className='px-3'>
          {
            listaRegistrosCalidad.length > 0
              ?
              listaRegistrosCalidad.map(registroCalidad => {
                return (
                  <CalidadRegistro key={registroCalidad.REGISTRO_CALIDAD_ID} registroCalidad={registroCalidad} />
                )
              })
              :
              <AlertaListaSinResultados />
          }
        </div>
      </div>
    </PageLayout>
  )
}

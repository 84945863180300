import { types } from '../types'


export const toggleSidebar = (data) => {
  return {
    type: types.toggleSidebar,
    payload: {
      isOpenSidebar: data
    }
  }
}
import React from 'react'
import { useCompruebaCuadrante } from '../helpers/useCompruebaCuadrante'
import { useTestConnection } from '../helpers/useTestConnection'

export const PageLayout = ({ children, testConnection = true, testCuadrante = true }) => {
  useTestConnection(testConnection)
  useCompruebaCuadrante(testCuadrante)
  return (
    <>
      {children}
    </>
  )
}
import { types } from '../types';

const initialValues = {
  listaAlmacenesOxigeno: [],
  respuestaRecogerOxigeno: '',
  respuestaDevolverOxigeno: ''
}

export const oxigenoReducer = (state = initialValues, action) => {
  switch (action.type) {
    case types.listarAlmacenesOxigeno:
      return {
        ...state,
        listaAlmacenesOxigeno: action.payload.listaAlmacenesOxigeno
      }
    case types.recogerOxigeno:
      return {
        ...state,
        respuestaRecogerOxigeno: action.payload.respuestaRecogerOxigeno
      }
    case types.devolverOxigeno:
      return {
        ...state,
        respuestaDevolverOxigeno: action.payload.respuestaDevolverOxigeno
      }
    default:
      return state;
  }
}
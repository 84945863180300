import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { marcarServicio, marcarServicioGenerico } from '../redux/actions/serviciosActions'
import 'animate.css'

export const ServicioFinalizarModalForm = ({ resolve, reject, servicio, codigoFinalizacion }) => {
  const dispatch = useDispatch()

  const pideCodigoFinalizacion = servicio.GESTION_FIN_SERVICIO.CODIGO_FINALIZACION
  const pideKmsRecorridos = servicio.GESTION_FIN_SERVICIO.KMS_RECORRIDOS
  const pidePacientesConSilla = servicio.GESTION_FIN_SERVICIO.PACIENTES_C_SILLA
  const pidePacientesSinSilla = servicio.GESTION_FIN_SERVICIO.PACIENTES_SIN_SILLA

  const { inCuadranteId, inIndicativoServicioId } = useSelector(({ loginReducer }) => loginReducer)

  const closeModal = () => {
    const element = document.getElementById('modal-container')
    element.parentNode.removeChild(element)
  }

  const cancel = () => {
    resolve('')
    closeModal()
  }

  const formik = useFormik({
    initialValues: {
      codigoFinalizacion: '',
      kmsRecorridos: 0,
      pacientesConSilla: 0,
      pacientesSinSilla: 0,
      pideCodigoFinalizacion: pideCodigoFinalizacion === 1 ? true : false,
      pideKmsRecorridos: pideKmsRecorridos === 1 ? true : false,
      pidePacientesConSilla: pidePacientesConSilla === 1 ? true : false,
      pidePacientesSinSilla: pidePacientesSinSilla === 1 ? true : false
    },
    validationSchema: Yup.object(
      {
        codigoFinalizacion: Yup.string().when('pideCodigoFinalizacion', {
          is: true,
          then: Yup.string().required('Campo requerido.'),
          otherwise: Yup.string()
        }),
        kmsRecorridos: Yup.number().when('pideKmsRecorridos', {
          is: true,
          then: Yup.number().required('Campo requerido.').positive().moreThan(0),
          otherwise: Yup.number()
        })
      }
    ),
    onSubmit: values => {
      setTimeout(async () => {
        // const confirm = await Confirm('Finalizar servicio. ¿Desea continuar?', 'Finalizar servicio', 'Aceptar', 'Cancelar')
        // if (confirm) {
        //   dispatch(marcarServicio(idServicio, codigoFinalizacion, anioServicio, formik.values.codigoFinalizacion, formik.values.kmsRecorridos, formik.values.pacientesConSilla, formik.values.pacientesSinSilla));
        //   setServicioFinalizado(true);

        // }
        servicio.TIPO_SERVICIO_TXT === 'GENERICO'
          ?
          dispatch(marcarServicioGenerico(inCuadranteId, inIndicativoServicioId, servicio.IN_SERVICIO_ID, codigoFinalizacion, servicio.IN_ANIO, formik.values.codigoFinalizacion, formik.values.kmsRecorridos, formik.values.pacientesConSilla, formik.values.pacientesSinSilla))
          :
          dispatch(marcarServicio(servicio.IN_SERVICIO_ID, codigoFinalizacion, servicio.IN_ANIO, formik.values.codigoFinalizacion, formik.values.kmsRecorridos, formik.values.pacientesConSilla, formik.values.pacientesSinSilla))
        cancel()
      }, 100);
    },
  });


  return (
    <div className='modal-card-container d-flex justify-content-center align-items-center'>
      <div className='card modal-card modal-card-xl animate__animated animate__fadeIn'>
        <div className='card-header card-header-primary'>
          {
            codigoFinalizacion === '900' && 'FINALIZAR SERVICIO'
          }
          {
            codigoFinalizacion === '1000' && 'ANULAR SERVICIO'
          }
          {
            codigoFinalizacion === '1100' && 'RESOLUCIÓN IN SITU'
          }
        </div>
        <div className='card-body'>
          <form id="form-finalizar-servicio" onSubmit={formik.handleSubmit}>
            {
              pideCodigoFinalizacion === 1
              &&
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="codigoFinalizacion" className="form-label">Seleccione una opción</label>
                  <select
                    className={`form-select ${(formik.touched.codigoFinalizacion && formik.errors.codigoFinalizacion) && 'form-control-error'}`}
                    id="codigoFinalizacion"
                    name="codigoFinalizacion"
                    value={formik.values.codigoFinalizacion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Seleccione una opción...</option>
                    <option key="0" value="10">0 - Falsa alarma</option>
                    <option key="1" value="11">1 - Ya evacuado</option>
                    <option key="2" value="12">2 - Ya asistido</option>
                    <option key="3" value="13">3 - Rechaza asistencia</option>
                    <option key="4" value="14">4 - Exitus</option>
                    <option key="5" value="15">5 - Rechaza traslado</option>
                    <option key="6" value="16">6 - Resolución In Situ</option>
                    <option key="7" value="17">7 - Otro recurso</option>
                    <option key="8" value="18">8 - Exitus In Itinere</option>
                    <option key="9" value="19">9 - Llegada con vida</option>
                    <option key="10" value="20">10 - Activación anulada por médico regulador</option>
                    <option key="11" value="21">11 - Fallo o Error</option>
                    <option key="12" value="22">12 - Anulación interna</option>
                    <option key="13" value="23">13 - Anulación externa</option>
                    <option key="14" value="24">14 - Sus medios</option>
                    <option key="15" value="25">15 - Simulacro</option>
                    <option key="16" value="26">16 - Código ICTUS</option>
                    <option key="20" value="30">20 - Alertado sin intervención</option>
                    <option key="21" value="31">21 - Transporte órganos</option>
                  </select>
                </div>
              </div>
            }

            <div className="row mb-3">
              {
                pideKmsRecorridos === 1
                &&
                <div className="col-sm-4">
                  <label htmlFor="kmsRecorridos" className="form-label">Kilómetros recorridos</label>
                  <input
                    type="number"
                    className={`form-control form-control-sm ${(formik.touched.kmsRecorridos && formik.errors.kmsRecorridos) && 'form-control-error'}`}
                    id="kmsRecorridos"
                    name="kmsRecorridos"
                    value={formik.values.kmsRecorridos}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              }
              {
                pidePacientesConSilla === 1
                &&
                <div className="col-sm-4">
                  <label htmlFor="pacientesConSilla" className="form-label">Pacientes con silla</label>
                  <input
                    type="number"
                    className={`form-control form-control-sm ${(formik.touched.pacientesConSilla && formik.errors.pacientesConSilla) && 'form-control-error'}`}
                    id="pacientesConSilla"
                    name="pacientesConSilla"
                    value={formik.values.pacientesConSilla}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              }
              {
                pidePacientesSinSilla === 1
                &&
                <div className="col-sm-4">
                  <label htmlFor="pacientesSinSilla" className="form-label">Pacientes sin silla</label>
                  <input
                    type="number"
                    className={`form-control form-control-sm ${(formik.touched.pacientesSinSilla && formik.errors.pacientesSinSilla) && 'form-control-error'}`}
                    id="pacientesSinSilla"
                    name="pacientesSinSilla"
                    value={formik.values.pacientesSinSilla}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              }
            </div>
            {
              (pideCodigoFinalizacion === 0 && pideKmsRecorridos === 0 && pidePacientesConSilla === 0 && pidePacientesSinSilla === 0)
              &&
              <div className='row mb-3'>
                <div className='col text-center'>
                  SE VA A MARCAR EL SERVICIO COMO
                  {
                    codigoFinalizacion === '900' && ' FINALIZADO. '
                  }
                  {
                    codigoFinalizacion === '1000' && ' ANULADO. '
                  }
                  {
                    codigoFinalizacion === '1100' && ' RESOLUCIÓN IN SITU. '
                  }
                  ¿DESEA CONTINUAR?
                </div>
              </div>
            }

          </form>
        </div>
        <div className='card-footer text-end'>
          <button className='btn btn-primary ms-1' onClick={cancel}>CERRAR</button>
          <button className='btn btn-primary ms-1' type='submit' form='form-finalizar-servicio'>ACEPTAR</button>
        </div>
      </div>
    </div>
  )
}
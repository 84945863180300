import { types } from '../types'

const initialValues = {
  centrosDeDia: []
}

export const centrosDeDiaReducer = (state = initialValues, action) => {
  switch (action.type) {
    case types.listarCentrosDeDia:
      return {
        centrosDeDia: action.payload.centrosDeDia,
      }
    default:
      return state
  }
}
import React from 'react'
import { NavLink } from 'react-router-dom'

export const Servicio = ({ servicio }) => {
  return (
    <div className='card custom-card mt-3'>
      <div className='card-header card-header-primary'>
        <div className='d-flex fw-bold'>
          <div
            className={`me-auto ${servicio.SERVICIO_ESTADO === 'REALIZANDOSE' && 'servicio-activo'
              }`}
          >
            {servicio.SERVICIO_ESTADO}
          </div>
          <div>{`Fecha Servicio: ${servicio.SERVICIO_FECHA_HORA_SERVICIO === '01-01-1990 00:00'
            ? '--/--/---- --:--'
            : servicio.SERVICIO_FECHA_HORA_SERVICIO
            }`}</div>
        </div>
      </div>
      <div className='card-body'>
        <table className='table table-borderless'>
          <tbody>
            <tr>
              <td className='fw-bold py-1'>Paciente</td>
              <td className='py-1'>{servicio.SERVICIO_PACIENTE}</td>
            </tr>
            <tr>
              <td className='fw-bold py-1'>Origen</td>
              <td className='py-1'>{servicio.SERVICIO_ORIGEN}</td>
            </tr>
            <tr>
              <td className='fw-bold py-1'>Destino</td>
              <td className='py-1'>{servicio.SERVICIO_DESTINO}</td>
            </tr>
            <tr>
              <td className='fw-bold py-1'>Inicio</td>
              <td className='py-1'>
                {servicio.SERVICIO_FECHA_HORA_INICIO === '01-01-1990 00:00' ||
                  servicio.SERVICIO_FECHA_HORA_INICIO === ''
                  ? '--/--/---- --:--'
                  : servicio.SERVICIO_FECHA_HORA_INICIO}
              </td>
            </tr>
            <tr>
              <td className='fw-bold py-1'>Fin</td>
              <td className='py-1'>
                {servicio.SERVICIO_FECHA_HORA_FIN === '01-01-1990 00:00' ||
                  servicio.SERVICIO_FECHA_HORA_FIN === ''
                  ? '--/--/---- --:--'
                  : servicio.SERVICIO_FECHA_HORA_FIN}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='card-footer'>
        <div className='d-flex align-items-center'>
          <div className='me-auto'>
            <span className={`badge rounded-pill bg-primary px-3 py-2`}>
              {servicio.TIPO_SERVICIO_TXT}
            </span>
          </div>
          <div>
            {
              servicio.SERVICIO_ESTADO === 'FINALIZADO' || servicio.SERVICIO_ESTADO === 'ANULADO' || servicio.SERVICIO_ESTADO === 'PENDIENTE FINALIZACION'
                ?
                <NavLink to={`/ver-servicio/${servicio.SERVICIO_ID}/${servicio.TABLA}/${servicio.ANIO}`} className='btn btn-primary me-1'>VER SERVICIO</NavLink>
                :
                <NavLink to={`/servicios-actuales/${servicio.SERVICIO_ID}`} className='btn btn-primary me-1'>VER SERVICIO</NavLink>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

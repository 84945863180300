import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../redux/store'
import 'animate.css'
import { useState, useEffect } from 'react'
import { fetchData } from '../redux/fetchData'
import { fileToBase64 } from '../helpers/fileToBase64'
import { formatFileSize } from '../helpers/formatFileSize'

export const ServicioFotosModal = ({ resolve, reject, servicio }) => {
  const [fotos, setFotos] = useState(null)
  const [fotosNuevas, setFotosNuevas] = useState([])
  const [loading, setLoading] = useState(null)

  useEffect(() => {
    const traerFotos = async () => {
      try {
        const proceso = 'A_VER_FOTOS_SERVICIO.PRO'
        let params = `?IN_Tabla=${servicio.TABLA}`
        params = params + `&IN_ANIO=${servicio.IN_ANIO}`
        params = params + `&IN_SERVICIO_ID=${servicio.IN_SERVICIO_ID}`
        const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
        const data = fetchData(url)
        data.then(data => {
          setFotos(data)
        })
      } catch (error) {
        console.log(error.message)
      }
    }
    !fotos && traerFotos()
  }, [fotos, servicio.IN_ANIO, servicio.IN_SERVICIO_ID, servicio.TABLA])

  const enviarFotos = async () => {
    fotosNuevas.map((e) => {
      try {
        const proceso = 'A_ANIADE_FOTO_A_SERVICIO.PRO'
        let params = `?IN_Tabla=${servicio.TABLA}`
        params = params + `&IN_ANIO=${servicio.IN_ANIO}`
        params = params + `&IN_SERVICIO_ID=${servicio.IN_SERVICIO_ID}`
        params = params + `&IN_FOTO=${e.split(',')[1]}`
        const url = `${process.env.REACT_APP_APIVEL}${proceso}${params}`
        const data = fetchData(url)
        setFotos(data.LISTA_IMAGENES)
        return data
      } catch (error) {
        console.log(error.message)
      }
      return e
    })
  }

  const accept = (values) => {
    enviarFotos()
    resolve(values)
    closeModal()
  }

  const cancel = () => {
    resolve('')
    closeModal()
  }

  const closeModal = () => {
    const element = document.getElementById('modal-container')
    element.parentNode.removeChild(element)
  }

  const getImageFromCamera = async (e) => {
    const image = e.target.files[0]
    const imageUrl = URL.createObjectURL(image)
    const fileSize = formatFileSize(image.size)
    const compressedImageInBase64 = await fileToBase64(image)
    setFotosNuevas([...fotosNuevas, compressedImageInBase64])
  }

  const adjuntarFoto = () => {
    document.getElementById('fotoServicio').click()
  }

  return (
    <Provider store={store}>
      <div className='modal-card-container d-flex justify-content-center align-items-center'>
        <div className='card modal-card animate__animated animate__fadeIn'>
          <div className='card-header card-header-primary'>FOTOS SERVICIO</div>
          <div className='card-body fotos-servicio'>
            <button className='btn btn-primary me-1' onClick={adjuntarFoto}>NUEVA FOTO</button>
            <div className='d-flex justify-content-center'>
              {
                fotosNuevas.map((e, index) => {
                  return (
                    <div key={index}>
                      <img src={e} alt='Foto servicio' className='img-thumbnail img-fotos-servicios' />
                    </div>
                  )
                })
              }
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <input
                  type='file'
                  className={`form-control form-control-sm no-show`}
                  id='fotoServicio'
                  name='fotoServicio'
                  capture='camera'
                  accept='image/jpeg'
                  onChange={getImageFromCamera}
                />
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              {
                fotos &&
                fotos.LISTA_IMAGENES.map((e, index) => {
                  return (
                    <div key={index}>
                      <img src={`data:image/jpg;base64,${e.IMAGEN}`} alt='Foto servicio' className='img-thumbnail img-fotos-servicios' />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className='card-footer text-end'>
            <button className='btn btn-primary me-1' onClick={cancel}>CANCELAR</button>
            <button className='btn btn-primary me-1' onClick={accept}>ACEPTAR</button>
          </div>
        </div>
      </div>
    </Provider>
  )
}
import { types } from '../types'

const initialValues = {
  listaRegistrosCalidad: [],
  registroCalidad: {}
}

export const calidadReducer = (state = initialValues, action) => {
  switch (action.type) {
    case types.listarRegistrosCalidad:
      return {
        ...state,
        listaRegistrosCalidad: action.payload.listaRegistrosCalidad
      }
    case types.verRegistroCalidad:
      return {
        ...state,
        registroCalidad: action.payload.registroCalidad
      }
    default:
      return state
  }
}
import React from 'react'
import { NavLink } from 'react-router-dom'

export const CalidadRegistro = ({ registroCalidad }) => {

  const fechaRegistro = registroCalidad.FECHAHORA_REGISTRO.slice(0, 10)
  const horaRegistro = registroCalidad.FECHAHORA_REGISTRO.slice(11)

  return (
    <div className='card mt-3'>
      <div className='card-header card-header-primary'>
        <div className='d-flex'>
          <div className='me-auto'>
            <span className='fw-bold'>{fechaRegistro}</span></div>
          <div>
            <span className='fw-bold'>{horaRegistro}</span></div>
        </div>
      </div>
      <div className='card-body py-4 text-center'>
        {registroCalidad.REGISTRO_NAME}
      </div>
      <div className='card-footer'>
        <div className='d-flex'>
          <div className='me-auto'>{`Editado el ${registroCalidad.FECHA_EDICION}`}</div>
          <div>
            <NavLink to={`/registro-calidad/${registroCalidad.REGISTRO_CALIDAD_ID}`} className='btn btn-primary me-1'>
              VER REGISTRO
            </NavLink>
          </div>
        </div>
      </div>
    </div >
  )
}
